import * as Yup from "yup";
import dateSchema from "../../../../inputs/dateInput/DateInput.schema";

var date = new Date();
date.setDate(date.getDate() - 120);

const requireString =
  (
    cond: "true" | "false"
  ): ((value: string, schema: Yup.StringSchema) => Yup.StringSchema) =>
  (value, schema) =>
    value === cond
      ? schema.required("This response is required.")
      : schema.notRequired();

const vaInfoSchema = Yup.object({
  fingerprintsOnFile: Yup.string()
    .default("")
    .required("This response is required."),
  fingerprintsDate: Yup.date()
    .min(date, `Date must be within 120 days ago from today`)
    .max(new Date(), "Date cannot exceed current date")
    .when("fingerprintsOnFile", requireString("true")),
  currentBadge: Yup.string().default("").required("This response is required."),
  previousBadge: Yup.string()
    .default("")
    .when("currentBadge", requireString("false")),
  currentEmail: Yup.string().default("").required("This field is required."),
  previousEmail: Yup.string()
    .default("")
    .when("currentEmail", requireString("false")),
});

export const vaInfoSchemaDynamic = (questions) =>
  Yup.object({
    fingerprintsOnFile: Yup.string().when("check", {
      is: () => questions.includes("fingerprintsOnFile"),
      then: Yup.string().default("").required("This response is required."),
      otherwise: Yup.string().default(""),
    }),

    fingerprintsDate: Yup.date()
      .min(date, `Date must be within 120 days ago from today`)
      .max(new Date(), "Date cannot exceed current date")
      .when("fingerprintsOnFile", requireString("true")),

    currentBadge: Yup.string().when("check", {
      is: () => questions.includes("currentBadge"),
      then: Yup.string().default("").required("This response is required."),
      otherwise: Yup.string().default(""),
    }),

    previousBadge: Yup.string()
      .default("")
      .when("currentBadge", requireString("false")),

    currentEmail: Yup.string().when("check", {
      is: () => questions.includes("currentEmail"),
      then: Yup.string().default("").required("This field is required."),
      otherwise: Yup.string().default(""),
    }),
    previousEmail: Yup.string().when("currentEmail", requireString("false")),
  });

export default vaInfoSchema;
