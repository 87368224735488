import React, { useEffect, useState } from 'react'; 
import { Button, Divider, Link, Typography } from '@material-ui/core';
import { Document, Page, pdfjs } from "react-pdf";
import axios from '../../../../axios';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const initialState = {
  loading: true,
  pdfData: null,
}

const PDFPreview = () => {
  const [state, setState] = useState({ ...initialState });
  const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(4);

  useEffect(() => {
    console.log('Entering component did mount');
    downloadSubmission('619e6cfd9149afb2b2565b33/101_Madhavaram6163_ROB.pdf');
   }, []);

 const downloadSubmission =  async (submission) => {
  console.log('Entering downloadSubmission');
  let headers = {
    Authorization: `Bearer ${localStorage.getItem('access-token')}`,
  }
  let data = null; 
  await axios
    .get(`submissions/download`, {
      headers,
      params: { key: submission },
      responseType: 'arraybuffer',
    })
    .then(async (response) =>
      {
        const pdfData = await response.data;
        console.log('*********************** DATA ****************',pdfData);
        const uintData = new Uint8Array(pdfData);
        console.log('*********************** UINT DATA ****************',uintData);
        setState({ loading: false, pdfData: uintData });
      }
    );

};

function onDocumentLoadSuccess({ numPages }) {
  setNumPages(numPages);
}

  return (
    <div style={{ float: 'center' }}>
      <Button
        variant="contained"
        style={{ float: 'right',  margin: '5px' }}
        onClick={() => alert('DOWNLOAD CLICKED')}
        disabled={false}
      >
        Download
      </Button>
      
      {state.loading
        ? <div>Loading ... </div>
        : <Document

          file={{ data: state.pdfData }}
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess} >
        
        {[...Array(numPages).keys()].map(page => (
            <Page pageNumber={page+1} />
        ))}
         
        </Document>
      }

      <Button
        variant="contained"
        style={{ float: 'right',  margin: '5px' }}
        onClick={() => alert('DOWNLOAD3 CLICKED')}
        disabled={false}
      >
        Download
      </Button>
      
    </div>
  );


};

export default PDFPreview;