import * as Yup from "yup";
import { isValidZip } from "../../inputs/zipInput/ZipInput";

const addressSchema = Yup.object({
  addressLineOne: Yup.string()
    .default("")
    .required("Address line one is required."),
  addressLineTwo: Yup.string().default("").nullable(),
  city: Yup.string().default("").required("City is required."),
  state: Yup.string().default("").required("State is required."),
  zip: Yup.string()
    .default("")
    .required("Zip code is required.")
    .test("valid zip", "Zip code must be 5 digits.", isValidZip),
}).defined();

export const addressSchemaDynamic = (questions) =>
  Yup.object({
    addressLineOne: Yup.string().when("check", {
      is: () => questions.includes("addressLineOne"),
      then: Yup.string().default("").required("Address line one is required."),
      otherwise: Yup.string().default(""),
    }),
    addressLineTwo: Yup.string().default("").nullable(),
    city: Yup.string().when("check", {
      is: () => questions.includes("city"),
      then: Yup.string().default("").required("City is required."),
      otherwise: Yup.string().default(""),
    }),
    state: Yup.string().when("check", {
      is: () => questions.includes("state"),
      then: Yup.string().default("").required("State is required."),
      otherwise: Yup.string().default(""),
    }),
    zip: Yup.string().when("check", {
      is: () => questions.includes("zip"),
      then: Yup.string()
        .default("")
        .required("Zip code is required.")
        .test("valid zip", "Zip code must be 5 digits.", isValidZip),
      otherwise: Yup.string().default(""),
    }),
  }).defined();

export type AddressSchema = Yup.InferType<typeof addressSchema>;
export default addressSchema;
