import { Button, createStyles, Grid, makeStyles, Theme, CircularProgress, Typography } from "@material-ui/core";
import axios from "../../axios";
import { Form, Formik } from 'formik';
import React from 'react';
import Spacer from '../../components/spacer/Spacer';
import { Textbox } from '../../inputs';
import changePasswordSchema, { ChangePasswordSchema } from './changePasswordSchema';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading: {
            marginLeft: "2px",
            color: "white"
        },
        progress: {
            textAlign: "center"
        },
        icon: {
            width: "100px",
            height: "100px",
            color: "green"
        },

    }));

const ChangePassword: React.FC = () => {
    const [showConfirmation, setConfirmation] = React.useState<boolean>(false);
    const [msg,setMsg ] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const classes = useStyles();

    const headers = {
        Authorization: `Bearer ${localStorage.getItem('access-token')}`,
    };

    const handleSubmit = ({ oldPassword, newPassword }: ChangePasswordSchema) => {
        setLoading(true)
        axios
            .post(`users/password`, { oldPassword, newPassword }, { headers })
            .then((response) => {
                setConfirmation(true);
                setMsg("");
            })
            .catch((error: any) => {
                setMsg(error?.response?.data?.detail)
            }).finally(() => setLoading(false))

    }
    return (
        <>
            {!showConfirmation ?
                <Formik
                    initialValues={changePasswordSchema.cast()}
                    enableReinitialize
                    validationSchema={changePasswordSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>

                        <Spacer direction="column">
                            <Typography variant="h5">{`Change Password`}</Typography>
                            {msg ? <Alert severity="error">{msg}</Alert>: null}
                            <Textbox name="oldPassword" label="Current Password" type="password" required/>
                            <Textbox name="newPassword" label="New Password" type="password" required/>
                            <Textbox name="confirmPassword" label="Confirm New Password" type="password" required/>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                            >
                                Change Password {loading && <CircularProgress className={classes.loading} size={14} />}
                            </Button>
                        </Spacer>

                    </Form>
                </Formik>
                :
                <Grid className={classes.progress}>
                    <CheckCircleOutlineIcon className={classes.icon} />
                    <Typography variant="h5">{`Password Changed Succesfully!`}</Typography>
                </Grid>}
        </>
    );
}

export default ChangePassword;
