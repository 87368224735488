import React from "react";
import { useField } from "formik";
import Spacer from "../../../../components/spacer/Spacer";
import { Typography, Divider } from "@material-ui/core";
import { buildInputName } from "../../../../utils/Utils";
import AdditionalExplanation from "../../../../components/additionalExplanation/AdditionalExplanation";
import BooleanRadio from "../../../../inputs/booleanRadio/BooleanRadio";
import { CompProps } from "../../PageConfigs";

const inputName = buildInputName("selectiveService");

const SelectiveService: React.FC<CompProps> = ({ questions, sections }) => {
  const [field] = useField("selectiveService");
  const isEligibleMale = field.value?.eligibleMale === "true";
  return (
    sections.includes("selective-service") && (
      <Spacer direction="column">
        <Typography variant="h5">Selective service registration</Typography>
        {questions.includes("eligibleMale") && (
          <BooleanRadio
            required={true}
            label={" Are you a male born after December 31, 1959?"}
            name={inputName("eligibleMale")}
          />
        )}
        {isEligibleMale && (
          <AdditionalExplanation
            id={"isRegistered"}
            name={inputName("isRegistered")}
            question="Have you registered with the Selective Service System?"
            explanation="Describe your reason(s)."
            showExplanation={(value) => value === "false"}
          />
        )}
        <Divider />
      </Spacer>
    )
  );
};

export default SelectiveService;
