import React from 'react';
import { Form as Formio } from 'react-formio';
import { useRouteMatch, useHistory } from 'react-router';
import axios from '../../axios';
import { Button, Typography } from '@material-ui/core';
import Spacer from '../../components/spacer/Spacer';

type GoBackProps = {
  onClick: () => void;
};

const GoBack: React.FC<GoBackProps> = ({ onClick }) => (
  <Button style={{ textAlign: 'center' }} variant="contained" onClick={onClick}>
    make edits
  </Button>
);

type RouteMatchProps = {
  id: string;
  form: string;
};

const FormioPdf: React.FC = () => {
  const {
    params: { id, form },
  } = useRouteMatch<RouteMatchProps>(`/form/:id/sign/:form`);
  const [state, setState] = React.useState({
    submission: undefined,
    form: { display: 'pdf' },
  });
  const { push } = useHistory();
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('access-token')}`,
  };

  React.useEffect(() => {
    axios
      .get(`forms/${id}/${form}`, { headers })
      .then((response) => {
        setState(response.data);
      })
      .catch(() => push('/'));
  }, [id, form]);

  const onSubmit = (submission) =>
    axios
      .put(`forms/${id}/${form}`, submission, {
        headers,
      })
      .then(() => push(`/form/${id}/sign/overview`));

  const onBack = () => push(`/form/${id}/start`);

  return (
    <div style={{ margin: '10% 0%' }}>
      <Spacer direction="column">
        <Typography style={{ textAlign: 'center' }} variant="h5">
          Please review, sign, and submit
        </Typography>
        <Typography style={{ textAlign: 'center' }} variant="subtitle1">
          If there are any errors, please use the MAKE EDITS button 
          to return to the process and make corrections. 
          When you have verified that all answers are accurate,
          Press the submit button to generate the forms for signature.
        </Typography>
        {state.submission && (
          <Formio
            className="form-frame"
            submission={state.submission}
            onSubmit={onSubmit}
            form={state.form}
          />
        )}
        <GoBack onClick={onBack} />
      </Spacer>
    </div>
  );
};

export default FormioPdf;
