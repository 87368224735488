import * as Yup from "yup";
import additionalInfoSchema from "../../modules/onboardingForm/pages/additionalInfo/AdditionalInfo.schema";

const additionalExplanationSchema = (
  name: string,
  condition = (value: string) => value === "true",
  questions = []
) => ({
  [name]: Yup.string().default("").required("This response is required."),
  [`${name}Explanation`]: Yup.string()
    .default("")
    .when(
      name,
      (value: string, schema: Yup.StringSchema): Yup.StringSchema =>
        condition(value) ? schema.required("Explanation is required.") : schema
    ),
});

export default additionalExplanationSchema;
