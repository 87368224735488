import { Button, CircularProgress, TableHead, withStyles, TextField, Typography } from '@material-ui/core';
import { AuthenticatedProps } from '../Common';
import { Table, TableBody, TableRow, TableCell, TableContainer} from '@material-ui/core';
import React, { ChangeEvent, ReactNode } from 'react';
import axios from '../../../axios';
import Spacer from '../../../components/spacer/Spacer';
import Select, { SelectOption } from '../../../inputs/select/Select';
import { useHistory } from 'react-router';
import NonZenefitsUserForm from '../nonZenefitsUsers/NonZenefitsUserForm';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { UserPreview } from "./ViewUser";
import MuiTableHead from "@material-ui/core/TableHead";
import User from '.';
import * as XLSX from 'xlsx';
import { Delete } from '@material-ui/icons';
import { ClipLoader } from 'react-spinners';
import { css } from "@emotion/react";
import { NonZenefitsUserFormSchema } from '../nonZenefitsUsers/NonZenefitsUserForm.schema';

type User = { name: string; id: string; projectId?: string };

const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;

type OnChangeHandler = (
  event: ChangeEvent<{ name?: string; value: unknown }>,
  child: ReactNode
) => void;

type ProjectSelectOption = {
  label: string;
  value: string;
  active?: boolean;
}

const Users: React.FC<AuthenticatedProps> = ({ getAccessToken, logout }) => {
  const [token, setToken] = React.useState<string>('');
  const [usersData, setUsersData] = React.useState<any[]>([]);
  const [users, setUsers] = React.useState<User[] | []>([]);
  const [manualUsers, setManualUsers] = React.useState<User[] | []>([]);
  const [userId, setUserId] = React.useState<string>('');
  const [user, setUser] = React.useState<User | null>(null);
  const [projects, setProjects] = React.useState<any[]>([]);
  const [project, setProject] = React.useState<ProjectSelectOption>({ label: "Select Project", value: "" });
  const [action, setAction] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const [showUsersTable, setShowUsersTable] = React.useState(false);
  const history = useHistory();
  const [error, setError] = React.useState<boolean>(false);

  const OPTIONS_LIMIT = 5;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  React.useEffect(() => {
    getAccessToken(['openid']).then(setToken);
  }, [getAccessToken, setToken]);
  const headers = { Authorization: `bearer ${token}` };

  const reload = () => {
    if (token !== '') {
      Promise.all([
        axios.get('users', { headers }).then((res) => setUsers(res.data)),
        axios.get('projects', { headers, params: { active: true } }).then((res) => setProjects(res.data)),
        axios.get('users', { headers, params: { user_type: "manual" } })
          .then((res) => {
            if (res.data)
              setManualUsers(res.data)
            else
              setManualUsers([])
          })
          .catch((e) => setManualUsers([]))
      ]);
    }
  }
  React.useEffect(() => {
    reload();
  }, [token]);

  const handleUserChange = (value) => {
    setUserId(value as string);
  };

  React.useEffect(() => {
    if (token !== undefined && token !== '') {
      axios
        .get<User>(`users/${userId}`, { headers })
        .then((res) => {
          setUser(res.data);
          if (res.data.projectId) {
            const projectsRefactored = projects?.filter((val: any) => val.active);
            const project_selected = projectsRefactored
              ?.filter((val: any) => val.id === res.data.projectId)
              ?.map((val2: any) => ({ label: val2.name, value: val2.id, active: val2.active }));
            if (project_selected.length === 0) {
              axios
                .get(`projects/${res.data.projectId}`, { headers })
                .then((response) => {
                  setProject({
                    label: response.data.name,
                    value: response.data.id,
                    active: response.data.active
                  });
                  const inactive_project = {
                    name: response.data.name,
                    id: response.data.id,
                    active: response.data.active
                  }
                  setProjects([inactive_project, ...projectsRefactored])
                });
            }
            else {
              setProject(project_selected[0]);
              setProjects(projectsRefactored);
            }

          }
          else {
            setProject({ label: "Select Project", value: "" });
          }
        });
    }
  }, [userId]);

  const handleActions: OnChangeHandler = (event) => {
    const { value } = event.target;
    setAction(value as string);
    setUserId("");
    setUser(null);
    setError(false);
    setProject({ label: "Select Project", value: "" });
  };

  const onProjectChange = (newValue: ProjectSelectOption) => {
    setProject(newValue);
    if (newValue.value === "")
      setError(true);
    else
      setError(false);
  }

  const onSave = () => {
    if (project.value === "") {
      setError(true);
    }
    else {
      const pId = project.value
      if (token !== undefined && token !== '') {
        axios
          .put(`users/${userId}`, { projectId: pId }, { headers })
          .then((response) => {
            console.log('INSIDE THEN');
            history.push({ pathname: `/admin/users/${userId}`, state: { detail: "Updated" } });
          })
          .catch(function (error) {
            if (error.response) {
              toast(error.response.data.detail);
            }
            else {
              toast(`User update unsuccessful`);
            }
          });
      }
    }
  }

  const formatPhoneNumber = (num) => {
    return num.toString().replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')

  }

  const saveUsers = () => {
    setLoading(true);
    usersData.forEach(user => {
      user['projectId'] = projects.find(project => project.name.trim() === user.project.trim()).id;
      user['name'] = user.firstname_lastname;
      user['phoneNumber'] = formatPhoneNumber(user['phoneNumber']);
      delete user['firstname_lastname'];
    })
    axios
        .post<any>("/users/bulk", usersData, { headers })
        .then(response => {
           
            history.push({pathname: "/admin/users/status", state:{detail:"Created Bulk",  data: response.data}});          
        })
        .catch(function (error) {
            if (error.response) {
              toast(error.response.data.detail); 
            }
            else{
              toast(`Users creation unsuccessful`); 
            }
           
          });
  }


  const actionOptions = [
    { label: 'Add New User', value: 'addNewUser' },
    { label: 'Assign Existing User to Project', value: 'updateExistingUser' },
    { label: 'Delete user', value: 'deleteManualUsers' },
    { label: 'Add Multiple Users', value: 'addMultipleUsers'}
  ];

  const TableHead = withStyles({
    root: {
      border: "black",
      padding: "1px",
      verticalAlign: 'top',
      width: '70%',
      background: 'linear-gradient(to right bottom, #223065, #FFFFFF)',
     
    }
  })(MuiTableHead);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setShowUsersTable(true);
      setUsersData(jsonData);
    };


    reader.readAsBinaryString(file);
  };

  const onDelete = () => {
    axios
      .delete(`users/${userId}`, { headers })
      .then((response) => {
        setUser(null);
        setUserId("");
        setAction("");
        reload();
        toast("User Deleted Successfully")
      })
      .catch((err) => {
        toast(`User Deletion unsuccessful`)
      })
  }

  return (
    <Spacer direction="column">
      <ToastContainer />
      <Spacer direction="column">
        <Typography variant="h5">Choose User Action</Typography>
        <Select
          name="userActions"
          value={action}
          options={actionOptions}
          onChange={handleActions}
        />
      </Spacer>
      {action && !(action === 'addNewUser' || action === 'addMultipleUsers') && (
        <Spacer direction="column">
          <Typography variant="h5">
            {action === "updateExistingUser" ? "Assign existing users to projects" : "Delete user"}
          </Typography>
          <Typography variant="subtitle1">
            {action === "updateExistingUser" ?
              'Begin typing a name to search all existing users – only first 5 users are shown by default. If user is not found, choose “add new user” above.' :
              "Search and select the user to be deleted"}
          </Typography>
          {loading ?
            <ClipLoader color="FFFFF" loading={loading} css={override} size={100} />
            :
            <Autocomplete
              value={user ? { label: user.name, value: user.id } : ""}
              filterOptions={filterOptions}
              getOptionLabel={(option: SelectOption) => option?.label || ""}
              id="user"
              getOptionSelected={(op: ProjectSelectOption) => op?.value == user?.id}
              ListboxProps={{ style: { maxHeight: "10rem" }, position: "bottom" }}
              options={
                action === "deleteManualUsers" ?
                  manualUsers?.map(({ name, id }) => ({ value: id, label: name })) :
                  users?.map(({ name, id }) => ({ value: id, label: name }))}
              onChange={(event, newValue: SelectOption) => {

                handleUserChange(newValue.value);
              }}
              renderInput={(params) => <TextField {...params} label="User" variant="outlined" />} />
          }
        </Spacer>
      )}
      {action === 'updateExistingUser' && userId && (
        <Spacer direction="column">
          <Typography variant="subtitle1">
            Select project to onboard the user to.
          </Typography>
          <Autocomplete
            value={project}
            getOptionDisabled={(op: ProjectSelectOption) => op?.active === false}
            getOptionSelected={(op: ProjectSelectOption) => op?.value == project?.value}
            getOptionLabel={(option: any) => option?.label}
            id="project"
            ListboxProps={{ style: { maxHeight: "10rem" }, position: "bottom" }}
            options={[{ label: "Select Project", value: "" }, ...projects.map(({ name, id, active = true }) => ({
              value: id,
              label: !active ? `${name} (Inactive)` : name,
              active: active
            }))]}
            onChange={(event, newValue) => {
              onProjectChange(newValue);
            }}
            onBlur={() => {
              if (project == null) {
                setProject({ label: "Select Project", value: "" });
                setError(true);
              }
            }}
            renderInput={(params) => <TextField
              {...params}
              label="Project"
              variant="outlined"
              error={error}
              helperText={error ? "Project is required" : ""}
            />}
          />

        </Spacer>
      )}
      {(action === 'deleteManualUsers' || action === 'updateExistingUser') && userId && (
        <>
          <UserPreview user={user} />
          {action === "deleteManualUsers" && <Button
            variant="contained"
            color="primary"
            onClick={() => onDelete()}
            style={{ marginTop: "30px" }}
            startIcon={<Delete />}
          >
            {'Delete User'}
          </Button>}
        </>
      )}
      {action === "updateExistingUser" && userId && <Button variant="contained" color="primary" onClick={onSave}>
        Save
      </Button>}
      {action === 'addNewUser' && (
        <NonZenefitsUserForm getAccessToken={getAccessToken} logout={logout} projects={projects} />
      )}
       {action === 'addMultipleUsers' && (
         <><label htmlFor={`upload-users-doc`}>
          <Button
            fullWidth
            variant="contained"
            component="span"
            style={{ width: "30%" }}
          >
            Upload
          </Button>

          <input
            hidden
            id={`upload-users-doc`}
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload} />
        </label>
        
         {showUsersTable && 
         <Spacer direction="column">
          <br></br>
        <Typography variant="h5">Add Users</Typography>
        <ClipLoader color={"FFFFF"} loading={loading} css={override} size={100} /><TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">User Name</TableCell>
                  <TableCell align="left">Phone number</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Project</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
            {usersData.map((user, j) => 
                <TableRow key={j}>
                  <TableCell key={user["firstname_lastname"]+j}>{user["firstname_lastname"]}</TableCell>
                  <TableCell key={user["phoneNumber"]+j}>{formatPhoneNumber(user["phoneNumber"])}</TableCell>
                  <TableCell key={user["userEmail"]+j}>{user["userEmail"]}</TableCell>
                  <TableCell key={user["project"]+j}>{user["project"]}</TableCell>
                </TableRow>
            )}
            </TableBody>
            </Table>
          </TableContainer>

          <br></br>
          <br></br>
          <Button variant="contained" color="primary" onClick={saveUsers}>
        Save
      </Button>          </Spacer>
} </>
      )}

    </Spacer>
  );
};

export default Users;
