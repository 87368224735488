import React from "react";
import Spacer from "../../../../components/spacer/Spacer";
import { buildInputName } from "../../../../utils/Utils";
import { Typography, Divider } from "@material-ui/core";
import AdditionalExplanation, {
  AdditionalExplanationProps,
} from "../../../../components/additionalExplanation/AdditionalExplanation";
import { CompProps } from "../../PageConfigs";

const inputName = buildInputName("backgroundInfo");

const questionsAdditional: AdditionalExplanationProps[] = [
  {
    name: inputName("criminalActivity"),
    id: "criminalActivity",
    question: `During the last 7 years, have you been convicted, been imprisoned, been on probation, or been on parole? (Include felonies, firearms or explosives violations, misdemeanors, and all other offenses.)`,
    explanation: `Provide the date, explanation of the violation, place of occurrence,and the name and address of the police department or court involved.`,
  },
  {
    name: inputName("courtMartial"),
    id: "courtMartial",
    question: `Have you been convicted by a military court-martial in the past 7 years? (If no military service, answer "NO.")`,
    explanation: `Provide the date, explanation of the violation, place of occurrence, and the name and address of the military authority or court involved.`,
  },
  {
    name: inputName("underCharges"),
    id: "underCharges",
    question: `Are you currently under charges for any violation of law?`,
    explanation: `Provide the date, explanation of the violation, place of occurrence, and the name and address of the police department or court involved.`,
  },
  {
    name: inputName("fired"),
    id: "fired",
    question: `During the last 5 years, have you been fired from any job for any reason, did you quit after beingtold that you would be fired, did you leave any job by mutual agreement because of specific problems, or were you debarred from Federal employment by the Office of Personnel Management or any other Federal agency?`,
    explanation: `Provide the date, explanation of the problem, reason for leaving, and the employer's name and address.`,
  },
  {
    name: inputName("delinquent"),
    id: "delinquent",
    question: `Are you delinquent on any Federal debt? (Includes delinquencies arising from Federal taxes, loans, overpayment of benefits, and other debts to the U.S. Government, plus defaults of Federally guaranteed or insured loans such as student and home mortgage loans.)`,
    explanation: `Provide the type, length, and amount of the delinquency or default, and steps that you are taking to correct the error or repay the debt.`,
  },
];

const BackgroundInfo: React.FC<CompProps> = ({ questions, sections }) =>
  sections.includes("background-info") && (
    <Spacer direction="column">
      <Typography variant="h5">Background information</Typography>
      {questionsAdditional
        .filter((q: any) => questions.includes(q.id))
        .map(AdditionalExplanation)}
      <Divider />
    </Spacer>
  );

export default BackgroundInfo;
