import * as Yup from "yup";


const credentialVerification = Yup.object({
    immunizationRecord: Yup.bool().default(false),
    backgroundInvestigation: Yup.bool().default(false),
    drugScreening: Yup.bool().default(false),
    caseLogs: Yup.bool().default(false),
    deaCertificate: Yup.bool().default(false),
    preEmployementResults: Yup.bool().default(false),
    other: Yup.bool().default(false),
    preEmploymentOnboarding: Yup.bool().default(false),
    otherInfo: Yup.bool().default(false),
    otherVerification: Yup.string().default(""),
    otherInfoText: Yup.string().default("")
  });

export const credentialVerificationSchemaDynamic = (questions) =>
    Yup.object({

        immunizationRecord: Yup.bool()
            .default(false),
        backgroundInvestigation: Yup.bool()
            .default(false),
        drugScreening: Yup.bool()
            .default(false),
        caseLogs: Yup.bool()
            .default(false),
        deaCertificate: Yup.bool()
            .default(false),
        preEmployementResults: Yup.bool()
            .default(false),
        other: Yup.bool()
            .default(false),
        preEmploymentOnboarding: Yup.bool()
            .default(false),
        otherInfo: Yup.bool()
            .default(false),
        otherVerification: Yup.string()
            .default(""),
        otherInfoText: Yup.string()
            .default("")

    });

 export default credentialVerification;   

