import React from "react";
import Spacer from "../spacer/Spacer";
import Textbox from "../../inputs/textbox/Textbox";
import { Divider, Typography } from "@material-ui/core";

type FullNameProps = {
  formGroup?: string;
  noDivider?: boolean;
  noQuestion?: boolean;
  noInstructions?: boolean;
  questions: string[];
};

const buildInputName = (name: string, formGroup?: string): string =>
  formGroup ? `${formGroup}.${name}` : name;

const FullName: React.FC<FullNameProps> = ({
  formGroup,
  noDivider = false,
  noQuestion = false,
  noInstructions = false,
  questions,
}) => (
  <Spacer direction="column">
    {!noQuestion && <Typography variant="h5">Full name</Typography>}
    {questions.includes("firstName") && !noInstructions && (
      <Typography variant="subtitle1">
        Provide your full legal name. If you have only initials in your name,
        provide them and indicate "Initial only". If you are a "Jr.," "Sr.,"
        etc. enter this after your last name.
      </Typography>
    )}

    {questions.includes("firstName") && (
      <Textbox
        required={true}
        name={buildInputName("firstName", formGroup)}
        label="First name"
      />
    )}
    {questions.includes("middleName") && !noInstructions && (
      <Typography variant="subtitle1">
        If no middle name, please enter "NMN"
      </Typography>
    )}
    {questions.includes("middleName") && (
      <Textbox
        required={true}
        name={buildInputName("middleName", formGroup)}
        label="Middle name"
      />
    )}
    {questions.includes("lastName") && (
      <Textbox
        required={true}
        name={buildInputName("lastName", formGroup)}
        label="Last name"
      />
    )}

    {!noDivider && <Divider />}
  </Spacer>
);

export default FullName;
