import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import axios from '../../../axios';
import { Button, TextField, Typography } from '@material-ui/core';
import nonZenefitsUserFormSchema, { NonZenefitsUserFormSchema } from './NonZenefitsUserForm.schema';
import Spacer from '../../../components/spacer/Spacer';
import Textbox from '../../../inputs/textbox/Textbox';
import PhoneInput from '../../../inputs/phoneInput/PhoneInput';
import { AuthenticatedProps } from '../Common';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select, { SelectOption } from '../../../inputs/select/Select';
import { useHistory } from "react-router-dom";
import { Autocomplete } from '@material-ui/lab';
type User = { name: string; id: string; projectId?: string };

interface UserProps extends AuthenticatedProps{
    projects: any[]
}


const NonZenefitsUserForm: React.FC<UserProps> = ({ getAccessToken, projects }) => {
    const [state, setState] = React.useState({ waiting: false });
    const [token, setToken] = React.useState<string>('');
    const [users, setUsers] = React.useState<User[]>([]);
    const [projectId, setProjectId]=React.useState<any>("");
    const history=useHistory();
    React.useEffect(() => {
        getAccessToken(['openid']).then(setToken);
    }, [getAccessToken, setToken]);
    const headers = { Authorization: `bearer ${token}` };

    const handleProjectChange = (value)=>{
        setProjectId(value);
    }
    const handleSubmit = (values: NonZenefitsUserFormSchema,
        { setErrors }: FormikHelpers<NonZenefitsUserFormSchema>
    ): void => {
        setState({ waiting: true });
        axios
            .post<any>("/users", {...values, projectId}, { headers })
            .then(response => {
                setState({ ...state, waiting: false });
                history.push({pathname: `/admin/users/${response?.data?.id}`, state:{detail:"Created"}});          
            })
            .catch(function (error) {
                if (error.response) {
                  toast(error.response.data.detail); 
                }
                else{
                  toast(`User creation unsuccessful`); 
                }
                setState({ waiting: false });
              });
    };

    return (
        <>
            <Formik
                initialValues={nonZenefitsUserFormSchema.cast()}
                enableReinitialize
                validationSchema={nonZenefitsUserFormSchema}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                valudationOnChange
            >
                {({handleChange,errors,setFieldValue})=>(
                <Form style={{ width: '100%' }}>
                    <Spacer direction="column">
                    <ToastContainer />
                        <Typography variant="h5">Add user</Typography>
                        <Textbox
                            name="name"
                            label="Enter first and last name"
                            disabled={state.waiting}
                        />
                        <PhoneInput
                            name="phoneNumber"
                            label="Enter phone number"
                            disabled={state.waiting}
                            setValue={setFieldValue}
                            handleChange={handleChange}
                        />
                        <Textbox
                            name="userEmail"
                            label="Enter email"
                            disabled={state.waiting}
                        />
                        <Spacer direction="column">
                            <Typography variant="subtitle1">
                                Select project to onboard the user to.
                            </Typography>
                            <Autocomplete
                              getOptionLabel={(option:SelectOption) => option.label}
                              getOptionSelected={(option, value) => option.value === value.value}
                              ListboxProps={{ style: { maxHeight: "10rem" }, position: "bottom" }}
                              options={projects.map(({ name, id }) => ({
                                value: id,
                                label: name,
                              }))}           
                             onChange={(event, newValue: SelectOption) => {
                                handleChange({
                                    target: { value: newValue?.value || "", name: "project" },
                                  });
                                handleProjectChange(newValue?.value || "");
                             }}
                             renderInput={(params) => <TextField {...params} 
                             label="Project" 
                             error={errors.project ? true : false} 
                             name="project" 
                             helperText={errors.project ? errors.project : null  } 
                             value={projectId}                              
                             variant="outlined"/>}
                            />
                        </Spacer>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={state.waiting}
                        >
                            {state.waiting ? 'Waiting' : 'Save'}
                        </Button>
                    </Spacer>
                </Form>)}
            </Formik>
        </>
    );
};

export default NonZenefitsUserForm;
