import React from "react";
import { useField } from "formik";
import Spacer from "../../../../components/spacer/Spacer";
import { Typography, Divider } from "@material-ui/core";
import { buildInputName } from "../../../../utils/Utils";
import BooleanRadio from "../../../../inputs/booleanRadio/BooleanRadio";
import { CompProps } from "../../PageConfigs";
import Checkbox from "../../../../inputs/checkbox/Checkbox";
import { Textbox } from "../../../../inputs";


const inputName = buildInputName("credentialVerification");

const CredentialVerification: React.FC<CompProps> = ({ questions, sections }) => {

    return (
        sections.includes("credential-verification") && (
            <Spacer direction="column">
                <Typography variant="h5">Credentialing Process</Typography>
                <Typography variant="subtitle1">I hereby authorize the release of the following information to Arrow ARC and its populating entities, Aptive Resources and Artemis ARC:</Typography>
                {questions.includes("immunizationRecord") && (
                    <><Checkbox
                        required={false}
                        label={"Immunization Record and Immunity-Related Test and Results"}
                        name={inputName("immunizationRecord")} />
                        <Checkbox
                            required={false}
                            label={"Background Investigation Results"}
                            name={inputName("backgroundInvestigation")} />
                        <Checkbox
                            required={false}
                            label={"Drug Screening Results"}
                            name={inputName("drugScreening")} />
                        <Checkbox
                            required={false}
                            label={"Case Logs"}
                            name={inputName("caseLogs")} />
                        <Checkbox
                            required={false}
                            label={"DEA Certificate Verification"}
                            name={inputName("deaCertificate")} />
                        <Checkbox
                            required={false}
                            label={"Pre-Emplopyment Physical Examination Results"}
                            name={inputName("preEmployementResults")} />
                        <Checkbox
                            required={false}
                            label={"Other: "}
                            name={inputName("other")} />
                         <Textbox required={false} name={inputName('otherVerification')} label="Other"/>   
                            </>
                )}

                <Divider />

                <Typography variant="subtitle1">I Understand this information will be used for:</Typography>
                <Checkbox
                            required={false}
                            label={"Pre-Emplopyment Onboarding and/or Credential Verification"}
                            name={inputName("preEmploymentOnboarding")} />
                        <Checkbox
                            required={false}
                            label={"Other: "}
                            name={inputName("otherInfo")} />
                         <Textbox required={false} name={inputName('otherInfoText')} label="Other"/> 
            </Spacer>
        )
    );
};

export default CredentialVerification;

