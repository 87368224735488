import * as Yup from "yup";

const selectiveServiceSchema = Yup.object({
  eligibleMale: Yup.string()
    .default("")
    .required("Selective service eligibilty is required."),
  isRegistered: Yup.string()
    .default("")
    .when("eligibleMale", (value, schema) =>
      value === "true" ? schema.required("This response is required.") : schema
    ),
  isRegisteredExplanation: Yup.string()
    .default("")
    .when(
      "isRegistered",
      (value: string, schema: Yup.StringSchema): Yup.StringSchema =>
        value === "false" ? schema.required("Explanation is required.") : schema
    ),
});

export const selectiveServiceSchemaDynamic = (questions) =>
  Yup.object({
    eligibleMale: Yup.string().when("check", {
      is: () => questions.includes("eligibleMale"),
      then: Yup.string()
        .default("")
        .required("Selective service eligibilty is required."),
      otherwise: Yup.string().default(""),
    }),
    isRegistered: Yup.string()
      .default("")
      .when("check", (value, schema) =>
        value === "true"
          ? schema.required("This response is required.")
          : schema
      ),
    isRegisteredExplanation: Yup.string()
      .default("")
      .when(
        "check",
        (value: string, schema: Yup.StringSchema): Yup.StringSchema =>
          value === "false"
            ? schema.required("Explanation is required.")
            : schema
      ),
  });

export default selectiveServiceSchema;
export type SelectiveServiceSchema = Yup.InferType<
  typeof selectiveServiceSchema
>;
