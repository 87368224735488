import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import axios from '../../axios';
import { useHistory } from 'react-router';
import Textbox from '../../inputs/textbox/Textbox';
import Spacer from '../../components/spacer/Spacer';
import { Button, Typography } from '@material-ui/core';
import startFormSchema, { StartFormSchema } from './StartForm.schema';
import { Alert } from '@material-ui/lab';
import jwt from 'jwt-decode';
import { useLocation } from "react-router-dom";

type alert = {
  alertMessage: string,
  type: "info" | "error"
}

type AuthProps = {
  setUserLogged: React.Dispatch<any>
}
const StartForm: React.FC<AuthProps> = ({ setUserLogged }) => {
  const [state, setState] = React.useState({ waiting: false });
  const history = useHistory();
  const data = useLocation();
  const [alert, setAlert] = React.useState<alert>({ alertMessage: "", type: "info" });

  React.useEffect(() => {
    setUserLogged(false)
  }, [])
  const handleSubmit = (
    { email, password }: StartFormSchema,
    { setErrors }: FormikHelpers<StartFormSchema>
  ): void => {
    setState({ waiting: true });
    const payload = {
      email: email,
      password: password
    }
    axios
      .post<string>(`initiate/find-user`, payload)
      .then((res) => {
        if (res.data["errorMessage"] === undefined) {
          if (res.data["formStatus"] === "SUBMITTED" || res.data["formStatus"] === "COMPLETED") {
            setState({ waiting: false });
            setAlert({ alertMessage: "User doesn't exist or has already submitted or completed forms", type: "info" });
          }
          else {
            const user: any = jwt(res?.data["access_token"]);
            localStorage.setItem('access-token', res?.data["access_token"]);
            history.push({ pathname: `/authenticate/${user?.user_id}`, state: { phoneNumber: res?.data["phoneNumber"] ?? "" } });
          }
        } else {
          setState({ waiting: false });
          setAlert({ alertMessage: res.data["errorMessage"], type: "error" });
        }
      })
      .catch((error) => {
        console.log('error response::', error);
        setState({ waiting: false });
        setAlert({ alertMessage: error.response.data.message, type: "error" });
      }).finally(() => {
        localStorage.removeItem('expiry-message')
      });
  };

  React.useEffect(() => {
    if(data?.state?.errorMessage){
      setAlert({ alertMessage: data?.state?.errorMessage, type: "error" });
    }
    else if (localStorage.getItem("expiry-message")) {
      setAlert({ alertMessage: localStorage.getItem("expiry-message"), type: "error" });
    }
    else{
      setAlert({ alertMessage: "" , type:null})
    }
  }, [])
  return (
    <>
      <Formik
        initialValues={startFormSchema.cast()}
        enableReinitialize
        validationSchema={startFormSchema}
        onSubmit={handleSubmit}
      >
        <Form style={{ width: '25%' }}>
          <Spacer direction="column">
            <Typography variant="h5">Login</Typography>
            <Typography variant="subtitle1">
              If you have an @aptiveresources.com email address enter it here.
              If you do not, or that does not work,
              enter your personal email address you have used to correspond with HR.
            </Typography>
            {alert.alertMessage &&
              <Alert
                severity={alert.type}
                style={{ backgroundColor: "#FFFFFF", color: alert.type === "error" ? "#FF0000" : "rgb(184, 231, 251)" }}>
                {alert.alertMessage.split(".").map((text: any)=> text && <> <p>{text+"."}</p></>)}
              </Alert>
            }
            <Textbox
              name="email"
              label="Email"
              autoFocus  onFocus={e => e.currentTarget.select()}
              disabled={state.waiting}
            />
            <Textbox
              name="password"
              type="password"
              label="Password"
              disabled={state.waiting}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={state.waiting}
            >
              {state.waiting ? 'Waiting' : 'Login'}
            </Button>
          </Spacer>
        </Form>
      </Formik>
    </>
  );
};

export default StartForm;
