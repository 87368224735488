import React from "react";
import Spacer from "../../../components/spacer/Spacer";
import { Typography, Divider } from "@material-ui/core";

const Start: React.FC = () => (
  <Spacer direction="column">
    <Typography variant="h5">Onboarding Form</Typography>
    <Typography variant="subtitle1">
    Please read all questions carefully and answer completely and honestly. 
    Responses will be verified by the agency you will be working for and delays or changes to employment status may be required if information is invalidated. 
    Some fields may be pre filled, make sure that information is correct.
    <br/>
    At the end of the process PDF forms will be generated - you must download, wet-sign and re-upload them as instructed.
    <br/>
    An asterisk * indicates the question is required.
    </Typography>
    <Divider />
  </Spacer>
);

export default Start;
