import { useField } from 'formik';
import React from 'react';
import Spacer from '../spacer/Spacer';

export type ConditionalWrapperProps = {
  dependency: string;
  condition: (value: any) => boolean;
};

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  children,
  dependency,
  condition,
}) => {
  const value = useField(dependency)[0].value;
  return condition(value) && <Spacer direction="column">{children}</Spacer>;
};

export default ConditionalWrapper;
