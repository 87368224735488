import React from "react";
import Spacer from "../../../../components/spacer/Spacer";
import { buildInputName } from "../../../../utils/Utils";
import { Divider, Typography } from "@material-ui/core";
import SmallTable from "../../../../components/smallTable/SmallTable";
import { serviceRecordSchema } from "./MilitaryServiceSchema";
import Textbox from "../../../../inputs/textbox/Textbox";
import DateInput from "../../../../inputs/dateInput/DateInput";
import { FormikSelect as Select } from "../../../../inputs/select/Select";
import BooleanRadio from "../../../../inputs/booleanRadio/BooleanRadio";
import ConditionalWrapper from "../../../../components/conditionalWrapper/ConditionalWrapper";
import { CompProps } from "../../PageConfigs";

const branchOptions = [
  { label: "Marine Corps", value: "Marine Corps" },
  { label: "Navy", value: "Navy" },
  { label: "Army", value: "Army" },
  { label: "Air Force", value: "Air Force" },
  { label: "Coast Guard", value: "Coast Guard" },
  { label: "Space Force", value: "Space Force" },
];

const inputName = buildInputName("militaryService");

const ServiceRecord: React.FC<{ formGroup: string }> = ({ formGroup }) => {
  const arrayInputName = buildInputName(formGroup);
  return (
    <Spacer direction="column">
      <Select
        required={true}
        name={arrayInputName("branch")}
        label="Branch"
        options={branchOptions}
      />
      <Spacer items={2}>
        <DateInput
          required={true}
          name={arrayInputName("fromDate")}
          label="From"
        />
        <DateInput required={true} name={arrayInputName("toDate")} label="To" />
      </Spacer>
      <Textbox
        required={true}
        name={arrayInputName("dischargeType")}
        label="Discharge type"
      />
    </Spacer>
  );
};

const MilitaryService: React.FC<CompProps> = ({ questions, sections }) => {
  return (
    sections.includes("military-service") && (
      <Spacer direction="column">
        <Typography variant="h5">Military service</Typography>
        {questions.includes("servedInMilitary") && (
          <BooleanRadio
            required={true}
            label={`Have you ever served in the United States military? If your only active duty was training in the Reserves or National Guard, answer "NO".`}
            name={inputName("servedInMilitary")}
          />
        )}

        <ConditionalWrapper
          dependency={inputName("servedInMilitary")}
          condition={(value) => value === "true"}
        >
          <Spacer direction="column">
            <Typography variant="subtitle1">
              List the branch, dates, and type of discharge from active duty.
            </Typography>
            <SmallTable
              formGroup={inputName("serviceRecords")}
              itemName="Service Record"
              schema={serviceRecordSchema}
              Component={ServiceRecord}
              maxItems={3}
            />
          </Spacer>
        </ConditionalWrapper>

        <Divider />
      </Spacer>
    )
  );
};

export default MilitaryService;
