import React from "react";
import Spacer from "../../../components/spacer/Spacer";
import Address from "../../../components/address/Address";
import ContactInfo from "../../../components/contactInfo/ContactInfo";

const Contact: React.FC<any> = ({ onChange, questions, sections }) => (
  <Spacer direction="column">
    {sections.includes("address") && <Address questions={questions} />}
    {sections.includes("contact") && (
      <ContactInfo onChange={onChange} questions={questions} />
    )}
  </Spacer>
);

export default Contact;
