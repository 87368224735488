import moment from "moment";
import * as Yup from "yup";
import dateSchema from "../../../../inputs/dateInput/DateInput.schema";

function validateDates(this: Yup.TestContext): boolean {
  const { fromDate, toDate } = this.parent as {
    fromDate: string;
    toDate: string;
  };
  // if either date is incomplete do not perform validation
  if (fromDate.length < 10 || toDate.length < 10) return true;
  const dates = [fromDate, toDate].map((date) => moment(date, "MM-DD-YYYY"));
  // if either date is invalid do not perform validation
  if (dates.map((date) => date.isValid()).includes(false)) return true;
  const [from, to] = dates;
  return from.isBefore(to);
}

const validateDatesTest = "Validate from and to date.";
const validateDatesMessage = "From date must be before to date.";

const dateFromToSchema = dateSchema
  .test(validateDatesTest, validateDatesMessage, validateDates)
  .required("This field is required.");

export const serviceRecordSchema = Yup.object({
  branch: Yup.string().default("").required("Branch of service is required."),
  fromDate: dateFromToSchema,
  toDate: dateFromToSchema,
  dischargeType: Yup.string()
    .default("")
    .required("Type of discharge is required."),
});

type ServiceRecordSchema = Yup.InferType<typeof serviceRecordSchema>;

const militaryServiceSchema = Yup.object({
  servedInMilitary: Yup.string()
    .default("")
    .required("Military service is required."),
  serviceRecords: Yup.array(serviceRecordSchema)
    .default([])
    .when("servedInMilitary", (value, schema) => {
      return value === "true"
        ? schema.required("At least one record is required.")
        : schema;
    }),
});

export const militaryServiceSchemaDynamic = (questions) =>
  Yup.object({
    servedInMilitary: Yup.string().when("check", {
      is: () => questions.includes("servedInMilitary"),
      then: Yup.string().default("").required("Military service is required."),
      otherwise: Yup.string().default(""),
    }),

    serviceRecords: Yup.array(serviceRecordSchema)
      .default([])
      .when("servedInMilitary", (value, schema) => {
        return value === "true"
          ? schema.required("At least one record is required.")
          : schema;
      }),
  });

export default militaryServiceSchema;
export type MilitaryServiceSchema = Yup.InferType<typeof militaryServiceSchema>;
