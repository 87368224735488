import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {NavLink} from 'react-router-dom'
import Submissions from './submissions/Submissions';
import Projects from './projects';
import Assigned from './assigned/Assigned';
import Rejected from './rejected';
import Users from './users';
import {AuthenticatedProps} from './Common';
import './index.scss'
import Completed from './submissions/Completed';
import ChangePassword from '../changePassword/ChangePassword';
import Analytics from './analytics/Analytics';

type AdminProps={
    setUserLogged: React.Dispatch<any>
}

const Admin: React.FC<AdminProps> = ({setUserLogged}) => {

    const logout: () => Promise<void> = async ()=>{
        setUserLogged(false);
        localStorage.removeItem("access-token");
    }

    const getAccessToken= (scopes: string[]) => {
        return Promise.resolve(localStorage.getItem('access-token'));
    };

    const authenticatedProps: AuthenticatedProps = {
        getAccessToken,
        logout,
    };

    const MenuBar = () => {
        return (

            <ul className="admin-menu-container">
                <div className='action-bar'>
                    <li className="menu-item">
                        <NavLink
                            activeClassName="selected"
                            to="/admin/users">Users</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink activeClassName="selected"
                                 to="/admin/projects">Projects</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink
                            activeClassName="selected"
                            to="/admin/assigned">Assigned</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink
                            activeClassName="selected"
                            to="/admin/submissions">Submissions</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink
                            activeClassName="selected"
                            to="/admin/completed">Completed</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink
                            activeClassName="selected"
                            to="/admin/rejected">Rejected</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink
                            activeClassName="selected"
                            to="/admin/analytics">Analytics(Beta)</NavLink>
                    </li>
                </div>
               
            </ul>
        )
    };

    return (


        <div className="admin-page-container">
            {/*only render menu bar if logged in as admin */}
            {localStorage.getItem('access-token') ? <MenuBar/> : null}
            <div className="page-container">
                <Switch>
                    <Route
                        exact
                        path="/admin/login"
                        render={() =>
                            localStorage.getItem('access-token') ? (
                                <Redirect to="/admin/submissions"/>
                            ) : (
                                <Redirect to="/login"/>
                                // <Login login={login}/>
                            )
                        }
                    />        
                    <Route path="/admin/:page">

                        <Switch>
                        <Route
                                path="/admin/change-password"
                                render={() =>
                                    localStorage.getItem('access-token') ? (
                                        <ChangePassword />
                                    ) : (
                                        <Redirect to="/admin/login"/>
                                    )
                                }
                            />
                        
                            <Route
                                exact
                                path="/admin/analytics"
                                render={() =>
                                    localStorage.getItem('access-token') ? (
                                        <Analytics {...authenticatedProps} />
                                    ) : (
                                        <Redirect to="/admin/login"/>
                                    )
                                }
                            />

                            <Route
                                path="/admin/users"
                                render={() =>
                                    localStorage.getItem('access-token') ? (
                                        <Users {...authenticatedProps} />
                                    ) : (
                                        <Redirect to="/admin/login"/>
                                    )
                                }
                            />
                            <Route
                                path="/admin/projects"
                                render={() =>
                                    localStorage.getItem('access-token') ? (
                                        <Projects {...authenticatedProps} />
                                    ) : (
                                        <Redirect to="/admin/login"/>
                                    )
                                }
                            />
                            <Route
                                path="/admin/assigned"
                                render={() =>
                                    localStorage.getItem('access-token') ? (
                                        <Assigned {...authenticatedProps} />
                                    ) : (
                                        <Redirect to="/admin/login"/>
                                    )
                                }
                            />
                            <Route
                                exact
                                path="/admin/submissions"
                                render={() =>
                                    localStorage.getItem('access-token') ? (
                                        <Submissions {...authenticatedProps} />
                                    ) : (
                                        <Redirect to="/admin/login"/>
                                    )
                                }
                            />
                            <Route
                                exact
                                path="/admin/completed"
                                render={() =>
                                    localStorage.getItem('access-token') ? (
                                        <Completed {...authenticatedProps} />
                                    ) : (
                                        <Redirect to="/admin/login"/>
                                    )
                                }
                            />
                            <Route
                                exact
                                path="/admin/rejected"
                                render={() =>
                                    localStorage.getItem('access-token') ? (
                                        <Rejected {...authenticatedProps} />
                                    ) : (
                                        <Redirect to="/admin/login"/>
                                    )
                                }
                            />
                            <Route
                                exact
                                path="/admin/*"
                                render={() =>
                                    localStorage.getItem('access-token') ? (
                                        <Redirect to="/admin/submissions"/>
                                    ) : (
                                        <Redirect to="/admin/login"/>
                                    )
                                }
                            />
                        </Switch>

                    </Route>
                    <Route
                        exact
                        path="/admin"
                        render={() =>
                            localStorage.getItem('access-token') ? (
                                <Redirect to="/admin/submissions"/>
                            ) : (
                                <Redirect to="/admin/login"/>
                            )
                        }
                    />
                </Switch>
            </div>
        </div>
    );
};

export default Admin;
