import * as Yup from "yup";

export const fullNameSchemaDynamic = (questions: any) =>
  Yup.object()
    .shape({
      firstName: Yup.string()
        .default("")
        .when("check", {
          is: () => questions.includes("firstName"),
          then: Yup.string().required("First name is required"),
          otherwise: Yup.string(),
        }),
      middleName: Yup.string().default(""),
      lastName: Yup.string()
        .default("")
        .when("check", {
          is: () => questions.includes("lastName"),
          then: Yup.string().required("Last name is required"),
          otherwise: Yup.string(),
        }),
    })
    .defined();

export const fullNameSchemaMiddleRequiredDynamic = (questions: any) =>
  Yup.object()
    .shape({
      firstName: Yup.string().when("check", {
        is: () => questions.includes("firstName"),
        then: Yup.string().default("").required("First name is required"),
        otherwise: Yup.string().default(""),
      }),
      // middleName: Yup.string().default('').required('Middle name is required'),
      lastName: Yup.string()
        .default("")
        .when("check", {
          is: () => questions.includes("lastName"),
          then: Yup.string().default("").required("Last name is required"),
          otherwise: Yup.string().default(""),
        }),
    })
    .defined();

const fullNameSchema = Yup.object()
  .shape({
    firstName: Yup.string().default("").required("First name is required"),
    middleName: Yup.string().default(""),
    lastName: Yup.string().default("").required("Last name is required"),
  })
  .defined();

export const fullNameSchemaMiddleRequired = Yup.object()
  .shape({
    firstName: Yup.string().default("").required("First name is required"),
    // middleName: Yup.string().default('').required('Middle name is required'),
    lastName: Yup.string().default("").required("Last name is required"),
  })
  .defined();
export type FullNameSchema = Yup.InferType<typeof fullNameSchema>;
export default fullNameSchema;
