import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router';
import logo from './aptive_logo_white.png';
import Navbar from './components/Navbar';
import AuthenticateForm from './modules/authenticate/AuthenticateForm';
import StartForm from './modules/start/StartForm';
import Complete from './modules/onboardingForm/Complete';
import OnboardingForm from './modules/onboardingForm/OnboardingForm';
import Admin from './modules/admin/Admin';
import Signatures from './modules/signatures/Signatures';
import PDFPreview from './modules/onboardingForm/pages/pdf/pdfPreview';
import Preview from './modules/onboardingForm/pages/Preview';
import ChangePassword from './modules/changePassword/ChangePassword';

const App: React.FC = () => {
  const [isUserLogged, setUserLogged] = React.useState<any>(localStorage.getItem("access-token"));
  return (
    <React.Fragment>
      <Navbar isUserLogged={isUserLogged} setUserLogged={setUserLogged} />
      <Switch>
        <Route exact path="/authenticate/:id">
          <div className="App">
            <AuthenticateForm setUserLogged={setUserLogged} />
          </div>
        </Route>
        <Route exact path="/change-password">
          <div className="App">
            <ChangePassword />
          </div>
        </Route>
        <Route exact path="/form/:id/:page">
          <div className="App">
            <OnboardingForm />
          </div>
        </Route>
        <Route exact path="/form/:id/sign/:page">
          <Signatures />
        </Route>
        <Route exact path="/form/:id/preview/:page">
          <PDFPreview />
        </Route>
        <Route exact path="/form/:id/preview3/:page">
          <Preview />
        </Route>
        <Route exact path="/complete">
          <div className="App">
            <Complete />
          </div>
        </Route>
        <Route path="/admin/:page?">
          <Admin setUserLogged={setUserLogged} />
        </Route>
        <Route exact>
          <div className="App">
            <StartForm setUserLogged={setUserLogged} />
          </div>
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
