import React from 'react';
import axios from '../../../axios';
import Spacer from '../../../components/spacer/Spacer';
import DateInput, { DateInputField } from "../../../inputs/dateInput/DateInput";
import { Typography, Button, withStyles } from '@material-ui/core';
import { AuthenticatedProps } from '../Common';
import { Table, TableBody, TableRow, TableCell, TableContainer} from '@material-ui/core';
import MuiTableHead from "@material-ui/core/TableHead";
import { css } from "@emotion/react";

import ClipLoader from "react-spinners/ClipLoader";

type Event = { event: string; event_count: string };

const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;


const Analytics: React.FC<AuthenticatedProps> = ({
  getAccessToken,
  logout,
}) => {
  const [analyticsData, setAnalyticsData] = React.useState<{}>({});
  const [eventsData, setEventsData] = React.useState<Event[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState<string>('');
  const [fromDate, setFromDate] = React.useState<string>('');
  const [toDate, setToDate] = React.useState<string>('');
  const headers = { Authorization: `bearer ${token}` };

  React.useEffect(() => {
    getAccessToken(['openid']).then(setToken);
  }, [getAccessToken, setToken]);

  const getAnalyticsData = ()=>{
    if (token !== '') {
      var params = new URLSearchParams();
      params.append("from_date", fromDate);
      params.append("to_date", toDate);
      axios
        .get(`analytics/totalcount`, { headers, params: params })
        .then((response) => {
          console.log('analytics Data:', response.data)
          setAnalyticsData(response.data);
          setEventsData(response.data["events"])
          setFromDate(response.data["from_date"])
          setToDate(response.data["to_date"])
          setLoading(false);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  React.useEffect(() => {
    if (token !== '') {
      getAnalyticsData();
    }
  }, [setAnalyticsData, setEventsData, setFromDate, setToDate, token]);

  const TableHead = withStyles({
    root: {
      border: "black",
      padding: "1px",
      verticalAlign: 'top',
      width: '70%',
      background: 'linear-gradient(to right bottom, #223065, #FFFFFF)',
     
    }
  })(MuiTableHead);

  return (
    <Spacer direction="column">
      <Typography variant="h5">Analytics Dashboard (Beta)</Typography>
      <ClipLoader color={"FFFFF"} loading={loading} css={override} size={100} />

      <Typography variant='body1'>
          <DateInputField id="fromDate" name={"fromDate"} label="From Date:" value={fromDate} 
            onChange={handleFromDateChange} 
            style={{ width: "15%", margin: "10px"}}
          />
          <DateInputField id="toDate" name="toDate" label="To Date:" value={toDate} 
            onChange={handleToDateChange}
            style={{ width: "15%", margin: "10px"}}
          />
          <Button
            variant="contained"
            component="span"
            style={{ width: "10%", margin: "25px"}}
            onClick={getAnalyticsData}
          >Apply
          </Button>
      </Typography>
      <Typography variant="h6">Total Events Count: {analyticsData["total_events"]}</Typography>
      <TableContainer>
          <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Event Name</TableCell>
              <TableCell align="left">Count</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
            {eventsData.map((element, j) => 
                <TableRow key={j}>
                  <TableCell key={element["event"]+j}>{element["event"]}</TableCell>
                  <TableCell key={element["event_count"]+j}>{element["event_count"]}</TableCell>
                </TableRow>
            )}
            </TableBody>
          </Table>
      </TableContainer>
    </Spacer>
  );
};

export default Analytics;
