import moment from 'moment';
import * as Yup from 'yup';

const dateSchema = Yup.string()
  .default('')
  .test('Validate date', 'Provide a valid date.', (value) => {
    const length = value.replace(/\D/g, '').length;
    if (length < 1) return true;
    return length < 8 ? false : validateDate(value);
  });

type DateValidator = (date: moment.Moment) => boolean;
const isValid: DateValidator = (date) => date.isValid();
const isModern: DateValidator = (date) => date.isAfter(moment('1899', 'YYYY'));
const isInPast: DateValidator = (date) => date.isBefore(moment.now());

const validateDate = (value: string): boolean => {
  const date = moment(value, 'MM-DD-YYYY');
  return ![isValid, isModern, isInPast].map((fn) => fn(date)).includes(false);
};

export default dateSchema;
