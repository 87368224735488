import React, { useEffect, useState } from "react";
import Spacer from "../../../components/spacer/Spacer";
import {
  Button,
  Divider,
  Card,
  CardContent,
  Typography,
  withStyles,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router";
import axios from "../../../axios";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiCardHeader from "@material-ui/core/CardHeader";
import jwt from "jwt-decode";

const initialState = {
  loading: true,
  pdfData: null,
};
const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "1px",
    verticalAlign: "top",
    width: "70%",
  },
})(MuiTableCell);

const CardHeader = withStyles({
  root: {
    borderBottom: "none",
    padding: "1px",
    verticalAlign: "top",
    background: "linear-gradient(to right bottom, #223065, #FFFFFF)",
    color: "#FFFFFF",
    height: 30,
  },
})(MuiCardHeader);

let initialDraftValues3 = {
  fullName: { firstName: "King", middleName: "Loui", lastName: "King" },
  otherNames: [],
  contactInfo: {},
  address: {},
  aboutYou: {
    placeOfBirth: {},
  },
  personalInfo: {},
  projectInfo: {},
  federalService: "noBreak",
  selectiveService: {},
  militaryService: { serviceRecords: [] },
  backgroundInfo: {},
  additionalInfo: {},
  vaInfo: {},
};

const Preview = () => {
  const [state, setState] = useState({ ...initialState });
  const [initialValues, setInitialValues] = useState(initialDraftValues3);
  const { push } = useHistory();
  const [sections, setSections] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [reviewed, setReviewed] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const [verified, setVerified] = React.useState(false);

  const {
    params: { id, page },
  } = useRouteMatch("/form/:id/:page");
  const user_id = jwt(localStorage.getItem("access-token"));

  const headers = {
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  };

  React.useEffect(() => {
    axios.get(`users/${user_id["user_id"]}`, { headers }).then(async (res) => {
      axios
        .get(`/configs/forms/questions/${res?.data?.projectId}`, {
          headers,
        })
        .then((configs) => {
          const page_custom_configs = [];

          Object.keys(configs?.data?.pages).forEach((key) =>
            page_custom_configs.push(...Object.keys(configs?.data?.pages[key]))
          );

          setSections(page_custom_configs);
          setQuestions(configs.data.questions);
          console.log("CUSTOM SECTIONS", page_custom_configs);
        })
        .catch(() => push("/"));
    });
  }, [id]);

  React.useEffect(() => {
    axios
      .get(`drafts/${id}`, {
        headers,
      })
      .then(({ data }) => {
        console.log(data.draft);
        setInitialValues(data.draft);
        // setPrepopulated(data.prepopulated);
      });
    //.catch(() => push('/'));
  }, [id, setInitialValues]);

  const handleSubmit = () => {
    push(`/form/${id}/sign/overview`);
  };

  var testBool = true;
  function boolChecker(currVal) {
    if (currVal == "true") {
      testBool = true;
      return "yes";
    } else {
      testBool = false;
      return "no";
    }
  }

  function serviceChecker(currVal) {
    if (currVal == "true") {
      return initialValues.militaryService.serviceRecords.map(
        (serviceRecord) => (
          <div>
            {" "}
            Branch - {serviceRecord.branch} from: {serviceRecord.fromDate} to:{" "}
            {serviceRecord.toDate} Discharge Type: {serviceRecord.dischargeType}{" "}
          </div>
        )
      );
    } else {
      return "n/a";
    }
  }

  function middleNameChecker(currVal) {
    let middleName = "";
    if (currVal.toUpperCase() != "NMN") {
      middleName = currVal;
    }
    return middleName;
  }

  function citizenshipChecker(currVal, citizen) {
    let citizenship = currVal;
    if (citizen == "true" || citizen == "naturalized") {
      citizenship = "USA";
    }
    return citizenship;
  }

  function citizenChecker(currVal) {
    let citizen = "yes";
    if (currVal == "false") {
      citizen = "no";
    }
    if (currVal == "naturalized") {
      citizen = "Naturalized";
    }
    return citizen;
  }

  function noMiddleNameChecker(currVal) {
    let middleName = "";
    if (currVal.toUpperCase() == "NMN") {
      middleName = "(No middle name)";
    }
    return middleName;
  }
  const Height = window.innerHeight;

  return (
    <>
      <div style={{ width: "70%", height: Height * 0.65, overflowY: "scroll" }}>
        <Typography variant="h5">
          {confirmed
            ? "Please review below information one more time and make necessary edits or generate PDF's."
            : "Please review below information and make necessary edits or confirm by clicking Next."}
        </Typography>
        <Typography variant="h5">
          {confirmed
            ? ""
            : "Please note you will be asked two more times to verify your information prior to completion."}
        </Typography>
        
        <>
          {sections.some((r) =>
            [
              "fullName",
              "otherNames",
              "birth",
              "citizen",
              "ssn",
              "ssnl4",
            ].includes(r)
          ) && (
            <Card>
              <CardContent>
                <CardHeader title="About you"></CardHeader>

                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {sections.includes("fullName") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Full Name:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.fullName.firstName}{" "}
                            {middleNameChecker(
                              initialValues.fullName.middleName
                            )}{" "}
                            {initialValues.fullName.lastName}{" "}
                            {noMiddleNameChecker(
                              initialValues.fullName.middleName
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {sections.includes("otherNames") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Other Names:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.otherNames.map((otherName) => (
                              <div>
                                {" "}
                                {otherName.firstName}{" "}
                                {middleNameChecker(otherName.middleName)}{" "}
                                {otherName.lastName}{" "}
                                {noMiddleNameChecker(otherName.middleName)}{" "}
                              </div>
                            ))}
                          </TableCell>
                        </TableRow>
                      )}
                      {sections.includes("birth") && (
                        <>
                          {questions.includes("dateOfBirth") && (
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "30%" }}
                              >
                                Date of Birth:
                              </TableCell>
                              <TableCell align="left">
                                {" " + initialValues?.aboutYou?.dateOfBirth ||
                                  "-"}
                              </TableCell>
                            </TableRow>
                          )}
                          {questions.includes("placeOfBirthCity") && (
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "30%" }}
                              >
                                Place of birth:
                              </TableCell>
                              <TableCell align="left">
                                {initialValues.aboutYou.placeOfBirth?.city},{" "}
                                {initialValues.aboutYou.placeOfBirth?.state},{" "}
                                {initialValues.aboutYou.placeOfBirth.country}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      {sections.includes("citizen") && (
                        <>
                          {questions.includes("citizen") && (
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "30%" }}
                              >
                                Citizen:
                              </TableCell>
                              <TableCell align="left">
                                {citizenChecker(initialValues.aboutYou.citizen)}
                              </TableCell>
                            </TableRow>
                          )}
                          {questions.includes("citizenship") && (
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "30%" }}
                              >
                                Citizenship:
                              </TableCell>
                              <TableCell align="left">
                                {citizenshipChecker(
                                  initialValues.aboutYou.citizenship,
                                  initialValues.aboutYou.citizen
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      {sections.includes("ssn") &&
                        questions.includes("ssn") && (
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              SSN:
                            </TableCell>
                            <TableCell align="left">
                              {initialValues.aboutYou.ssn}
                            </TableCell>
                          </TableRow>
                        )}
                      {sections.includes("ssn") &&
                        questions.includes("ssnl4") && (
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              SSN last 4 digits:
                            </TableCell>
                            <TableCell align="left">
                              {initialValues.aboutYou.ssnl4}
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          {sections.includes("additional-info") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Additional Information"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {questions.includes("relatives") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Relatives Working in Agency or Government:
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(
                                initialValues.additionalInfo.relatives
                              )}
                            </TableCell>
                          </TableRow>
                          {initialValues.additionalInfo.relatives && (
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "30%" }}
                              >
                                Relatives Explanation:
                              </TableCell>
                              <TableCell align="left">
                                {initialValues.additionalInfo.relatives ==
                                "true"
                                  ? initialValues.additionalInfo
                                      .relativesExplanation
                                  : null}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      {questions.includes("benefits") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Recieved or Applied for Benefits:
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(
                                initialValues.additionalInfo.benefits
                              )}
                            </TableCell>
                          </TableRow>
                          {initialValues.additionalInfo.benefits && (
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "30%" }}
                              >
                                Benefits Explanation:{" "}
                              </TableCell>
                              <TableCell align="left">
                                {
                                  initialValues.additionalInfo
                                    .benefitsExplanation
                                }
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          {sections.includes("address") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Address"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow></TableRow>
                      {questions.includes("addressLineOne") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Home Address Line 1:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.address.addressLineOne}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("addressLineTwo") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Home Address Line 2:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.address.addressLineTwo}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.some((r) =>
                        ["city", "state", "zip"].includes(r)
                      ) && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Home City, Home State and Home Zip:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.address.city}{" "}
                            {initialValues.address.state}{" "}
                            {initialValues.address.zip}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          {sections.includes("contact") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title=" Contact Info"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {questions.includes("dayPhone") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Day Phone:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.contactInfo.dayPhone}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("nightPhone") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Night Phone:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.contactInfo.nightPhone}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("email") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Email:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.contactInfo.email}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("vaEmail") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            VA Email:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.contactInfo.vaEmail}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("emergencyContact") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Emergency Contact:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.contactInfo.emergencyContact}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("emergencyContactNo") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Emergency Contact Number:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.contactInfo.emergencyContactNo}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          {sections.includes("personal-info") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Personal Info"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {(questions.includes("heightFeet") ||
                        questions.includes("heightInches")) && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Height:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.personalInfo.heightFeet} feet{" "}
                            {initialValues.personalInfo.heightInches} inches
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("weightLBs") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Weight:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.personalInfo.weight} lbs
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("hairColor") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Hair color:
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            {initialValues.personalInfo.hairColor}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("eyeColor") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Eye color:
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            {initialValues.personalInfo.eyeColor}{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("gender") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Gender:
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            {initialValues.personalInfo.gender}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("race") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Race:
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            {initialValues.personalInfo.race}{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("maritalStatus") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Marital Status:
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            {initialValues.personalInfo.maritalStatus}{" "}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          {sections.includes("background-info") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Background Info"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {questions.includes("criminalActivity") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Commited Criminal Activity:
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {boolChecker(
                                initialValues.backgroundInfo.criminalActivity
                              )}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              {" "}
                              {initialValues.backgroundInfo.criminalActivity ==
                              "true"
                                ? "Commited Criminal Activity Explanation:"
                                : null}
                            </TableCell>
                            <TableCell align="left">
                              {initialValues.backgroundInfo.criminalActivity ==
                              "true"
                                ? initialValues.backgroundInfo
                                    .criminalActivityExplanation
                                : null}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {questions.includes("courtMartial") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Convicted by Court Martial:
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {boolChecker(
                                initialValues.backgroundInfo.courtMartial
                              )}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              {initialValues.backgroundInfo.courtMartial ==
                              "true"
                                ? "Court Martial Explanation:"
                                : null}
                            </TableCell>
                            <TableCell align="left">
                              {initialValues.backgroundInfo.courtMartial ==
                              "true"
                                ? initialValues.backgroundInfo
                                    .courtMartialExplanation
                                : null}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {questions.includes("underCharges") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Under Charges:
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(
                                initialValues.backgroundInfo.underCharges
                              )}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              {initialValues.backgroundInfo.underCharges ==
                              "true"
                                ? "Under Charges Explanation:"
                                : null}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {initialValues.backgroundInfo.underCharges ==
                              "true"
                                ? initialValues.backgroundInfo
                                    .underChargesExplanation
                                : null}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {questions.includes("fired") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Fired/Debarred:
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(initialValues.backgroundInfo.fired)}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              {initialValues.backgroundInfo.fired == "true"
                                ? "Fired/Debarred Explanation:"
                                : null}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {initialValues.backgroundInfo.fired == "true"
                                ? initialValues.backgroundInfo.firedExplanation
                                : null}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {questions.includes("delinquent") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Delinquent on any Federal Debt:
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(
                                initialValues.backgroundInfo.delinquent
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              {initialValues.backgroundInfo.delinquent == "true"
                                ? "Delinquent Explanation:"
                                : null}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {initialValues.backgroundInfo.delinquent == "true"
                                ? initialValues.backgroundInfo
                                    .delinquentExplanation
                                : null}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          {sections.includes("project-info") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Project Info"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {questions.includes("positionTitle") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Position Title:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.projectInfo.positionTitle}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("companyName") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Company Name:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.projectInfo.companyName}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("contractEndDate") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Contract End Date:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.projectInfo.contractEndDate}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          {sections.includes("selective-service") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Selective Service"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {questions.includes("eligibleMale") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Born after Dec 31, 1959?:
                          </TableCell>
                          <TableCell align="left">
                            {boolChecker(
                              initialValues.selectiveService.eligibleMale
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("isRegistered") && (
                        <>
                          {initialValues.selectiveService.eligibleMale ===
                            "true" && (
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "30%" }}
                              >
                                Registered with Selective Service:
                              </TableCell>
                              <TableCell align="left">
                                {boolChecker(
                                  initialValues.selectiveService.isRegistered
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          {initialValues.selectiveService.isRegistered ===
                            "false" && (
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "30%" }}
                              >
                                Service Explanation:
                              </TableCell>
                              <TableCell align="left">
                                {
                                  initialValues.selectiveService
                                    .isRegisteredExplanation
                                }
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          {sections.includes("va-info") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Fingerprinting Information"> </CardHeader>

                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {questions.includes("fingerprintsOnFile") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Fingerprints on File:
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(
                                initialValues.vaInfo.fingerprintsOnFile
                              )}
                            </TableCell>
                          </TableRow>
                          {initialValues.vaInfo.fingerprintsOnFile && (
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "30%" }}
                              >
                                Date fingerprinted:
                              </TableCell>
                              <TableCell align="left">
                                {initialValues.vaInfo.fingerprintsDate}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      {questions.includes("currentBadge") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Have Current VA Badge:
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(initialValues.vaInfo.currentBadge)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Have had previous VA Badge:
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(initialValues.vaInfo.previousBadge)}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {questions.includes("currentEmail") && (
                        <>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Have current VA email/IT access{" "}
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(initialValues.vaInfo.currentEmail)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "30%" }}
                            >
                              Previously had VA email/IT access:
                            </TableCell>
                            <TableCell align="left">
                              {boolChecker(initialValues.vaInfo.previousEmail)}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}

          {sections.includes("military-service") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Military service"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {questions.includes("servedInMilitary") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Have you ever served in the United States military?
                            :
                          </TableCell>
                          <TableCell align="left">
                            {boolChecker(
                              initialValues.militaryService.servedInMilitary
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("servedInMilitary") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Service Records:
                          </TableCell>
                          <TableCell align="left">
                            {serviceChecker(
                              initialValues.militaryService.servedInMilitary
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}

          {sections.includes("prevent-assault-info") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Prevent Assault Info"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {questions.includes("sexualAbuse") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Engaged in Sexual Abuse:
                          </TableCell>
                          <TableCell align="left">
                            {boolChecker(
                              initialValues.preventAssaultInfo.sexualAbuse
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("engageSexualActivity") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Convicted in Sexual Activity:
                          </TableCell>
                          <TableCell align="left">
                            {boolChecker(
                              initialValues.preventAssaultInfo
                                .engageSexualActivity
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("victimConsent") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Convicted in Sexual Activity (victim did not
                            consent):
                          </TableCell>
                          <TableCell align="left">
                            {boolChecker(
                              initialValues.preventAssaultInfo.victimConsent
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {questions.includes("civilAdminAdjudication") && (
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Civil/Administrative Adjudication:
                          </TableCell>
                          <TableCell align="left">
                            {boolChecker(
                              initialValues.preventAssaultInfo
                                .civilAdminAdjudication
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          {sections.includes("credential-verification") && (
            <Card marginTop={5}>
              <CardContent>
                <CardHeader title="Credential Verification Authorize"></CardHeader>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow></TableRow>
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", width: "30%" }}
                          >
                            Authorize the information:
                          </TableCell>
                          <TableCell align="left">
                            {initialValues.credentialVerification.immunizationRecord && <>{'Immunization Record and Immunity-Related Test and Results'} <br/> </>}
                            {initialValues.credentialVerification.backgroundInvestigation &&  <>{'Background Investigation Results'} <br/> </>}
                            {initialValues.credentialVerification.drugScreening && <>{'Drug Screening Results '}<br/></>}
                            {initialValues.credentialVerification.caseLogs && <>{'Case Logs'}<br/> </>}
                            {initialValues.credentialVerification.deaCertificate &&  <>{'DEA Certificate Verification'} <br/> </>}
                            {initialValues.credentialVerification.preEmployementResults && <> {'Pre-Emplopyment Physical Examination Results'} <br/> </>}
                            {initialValues.credentialVerification.preEmploymentOnboarding && <>{'Pre-Emplopyment Onboarding and/or Credential Verification'} <br/> </>}
                            {initialValues.credentialVerification.otherVerification && <>{initialValues.credentialVerification.otherVerification} <br/></>}
                            {initialValues.credentialVerification.otherInfoText && <>{initialValues.credentialVerification.otherInfoText}</>}

                          </TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
        </>
      </div>
      <div style={{ width: "70%" }}>
        <Spacer direction="column">
          <Divider
            container
            style={{ marginTop: "25px", marginBottom: "10px" }}
          />
          {!confirmed ? (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={reviewed}
                  onChange={(e) => {
                    setReviewed(e.target.checked);
                  }}
                />
              }
              label={"I reviewed the above informantion and its correct."}
            />
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={verified}
                  onChange={(e) => {
                    setVerified(e.target.checked);
                  }}
                />
              }
              label={
                "Please verify the information entered above is correct  because it will be used to populate the documents.              "
              }
            />
          )}
          <>
            <Button
              variant="contained"
              style={{ float: "left" }}
              onClick={() => push(`/form/${id}/start`)}
            >
              make edits
            </Button>
            {!confirmed ? (
              <Button
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={(e) => setConfirmed(true)}
                disabled={!reviewed}
              >
                <span style={{ textTransform: "none" }}>NEXT</span>
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={handleSubmit}
                disabled={!verified}
              >
                <span style={{ textTransform: "none" }}>GENERATE PDF's</span>
              </Button>
            )}
          </>
        </Spacer>
      </div>
    </>
  );
};

export default Preview;
