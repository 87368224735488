import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import axios from '../../../../axios';
import jwt from "jwt-decode";
import { openDB } from 'idb';



const initDB = async () => {
  return openDB('pdfStorage', 1, {
      upgrade(db) {
          if (!db.objectStoreNames.contains('pdfs')) {
              db.createObjectStore('pdfs', { keyPath: 'id' });
          }
      },
  });
};

const generatePDF = async (passedRef: React.RefObject<HTMLDivElement>, name: string ) => {
  if (!passedRef.current) return;

  const canvas = await html2canvas(passedRef.current, {scale: 3});
  const imgData = canvas.toDataURL("image/png");
  const pdf = new jsPDF();
  const imgWidth = 190;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;

  pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
  // Convert PDF to Blob for uploading
  const pdfBlob = pdf.output("blob");
 const db = await initDB();
 await db.put('pdfs', { id: name, pdfBlob });
 console.log('PDF stored in IndexedDB');
};

export { generatePDF };