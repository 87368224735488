import React from "react";
import { FieldArray, useField } from "formik";
import * as Yup from "yup";
import { Button, Divider, FormHelperText } from "@material-ui/core";
import Spacer from "../spacer/Spacer";
import { buildInputName } from "../../utils/Utils";

export type SmallTableProps = {
  formGroup: string;
  Component: React.FC<{ formGroup: string }>;
  schema: Yup.ObjectSchema;
  itemName: string;
  maxItems?: number;
};

function SmallTable<T>({
  formGroup,
  Component,
  schema,
  itemName,
  maxItems = undefined,
}: SmallTableProps): JSX.Element {
  const [{ value }, { error }] = useField<T[] | undefined>(formGroup);
  const buildName = buildInputName(formGroup);
  const hasError = error && !Array.isArray(error);
  return (
    <FieldArray
      name={formGroup}
      render={(arrayHelpers) => (
        <Spacer direction="column">
          {value?.map((_, index) => (
            <Spacer direction="column" key={`${formGroup}-${index}`}>
              <Component formGroup={buildName(index)} />
              <Button
                variant="contained"
                onClick={() => arrayHelpers.remove(index)}
              >
                {`Remove ${itemName}`}
              </Button>
              <Divider />
            </Spacer>
          ))}
          <Spacer>
          {(!maxItems || value.length < maxItems) && (
            <Button
              variant="contained"
              onClick={() => arrayHelpers.push(schema.cast())}
            >
              {value && value.length > 0
                ? `Add another ${itemName}`
                : `Add ${itemName}`}
            </Button>
          )}
          </Spacer>
          {hasError && (
            <FormHelperText error style={{ margin: "0px 14px" }}>
              {error}
            </FormHelperText>
          )}
        </Spacer>
      )}
    />
  );
}

export default SmallTable;
