import React from 'react';
import { Route, Switch, } from 'react-router';
import {useRouteMatch} from "react-router-dom";
import Users from "./Users";
import ViewUser from './ViewUser';
import { AuthenticatedProps } from '../Common';
import UserStatus from './UserStatus';

const User: React.FC<AuthenticatedProps> = (authenticatedProps)=>{
     const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path= {`${path}/status`}>
                <UserStatus {...authenticatedProps}/>
            </Route>
             <Route path={`${path}/:userId`}>
                <ViewUser {...authenticatedProps}/>
            </Route>
            <Route>
                <Users {...authenticatedProps}/>
            </Route>
        </Switch>
    )

}

export default User;