import React from 'react';
import Textbox, { TextboxProps } from '../textbox/Textbox';

export type TextareaProps = Omit<TextboxProps, 'multiline' | 'rows'>;

const Textarea: React.FC<TextareaProps> = (props) => (
  <Textbox multiline rows={4} {...props} />
);

export default Textarea;
