import React from 'react';
import { Route, Switch, } from 'react-router';
import {useRouteMatch} from "react-router-dom";
import Projects from "./Projects";
import ViewProject from './ViewProject';
import { AuthenticatedProps } from '../Common';

const Project: React.FC<AuthenticatedProps> = (authenticatedProps)=>{
     const { path } = useRouteMatch();
    return (
        <Switch>
             <Route path={`${path}/:projectId`}>
                <ViewProject {...authenticatedProps}/>
            </Route>
            <Route>
                <Projects {...authenticatedProps}/>
            </Route>
        </Switch>
    )

}

export default Project;