import React from 'react';
import { Route, Switch } from 'react-router';
import Preview from '../onboardingForm/pages/Preview';
import FormioPdf from './FormioPdf';
import Overview from './Overview';
import Uploads from './Uploads';

const Signatures: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/form/:id/sign/overview">
        <div className="App">
          <Overview />
        </div>
      </Route>
      <Route exact path="/form/:id/sign/preview3">
        <div className="App">
          <Preview />
        </div>
      </Route>
      <Route exact path={`/form/:id/sign/upload`}>
        <div className="App">
          <Uploads />
        </div>
      </Route>
      <Route exact path={`/form/:id/sign/:form`}>
        <FormioPdf />
      </Route>
    </Switch>
  );
};

export default Signatures;
