import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const LinearProgressWithLabel : React.FC<LinearProgressProps> = (props) => {
    return (
      <Box display="flex" width="100%" alignItems="center" justifyContent="center">
        <Box width="50%" mr={1}>
          <LinearProgress variant="determinate"  {...props}/>
        </Box>
        <Box minWidth={5}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
 
  export default LinearProgressWithLabel;