import React from "react";
import { FieldArray, useField } from "formik";
import fullNameSchema, { FullNameSchema } from "../fullName/FullName.schema";
import FullName from "../fullName/FullName";
import { Button, Divider, Typography } from "@material-ui/core";
import Spacer from "../spacer/Spacer";
import { buildInputName } from "../../utils/Utils";
import { CompProps } from "../../modules/onboardingForm/PageConfigs";

const buildName = buildInputName("otherNames");

const OtherNames: React.FC<CompProps> = ({ questions, sections }) => {
  const [{ value }] = useField<FullNameSchema[] | undefined>("otherNames");
  return (
    <FieldArray
      name="otherNames"
      render={(arrayHelpers) => (
        <Spacer direction="column">
          <Typography variant="h5">Other names used</Typography>
          <Typography variant="subtitle1">
            Please enter other names or aliases you have used, if any.
          </Typography>
          {value?.map((_, index) => (
            <Spacer direction="column" key={`othername-${index}`}>
              <FullName
                questions={questions}
                formGroup={buildName(index)}
                noDivider
                noQuestion
                noInstructions
              />
              <Button
                variant="contained"
                onClick={() => arrayHelpers.remove(index)}
              >
                Remove Name
              </Button>
              <Divider />
            </Spacer>
          ))}
          <Button
            variant="contained"
            onClick={() => arrayHelpers.push(fullNameSchema.cast())}
          >
            {value && value.length > 0 ? "Add another name" : "Add a name"}
          </Button>
          <Divider />
        </Spacer>
      )}
    />
  );
};

export default OtherNames;
