import React from 'react';
import { Grid, GridSpacing } from '@material-ui/core';

type SpacerProps = {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  items?: 1 | 2 | 3;
};

const Spacer: React.FC<SpacerProps> = ({ children, direction, items = 1 }) => (
  <Grid container spacing={2} direction={direction}>
    {(Array.isArray(children) ? children : [children])
      .map((child, index) => (
        <Grid item xs={(12 / items) as 12 | 6 | 4} key={`spacer-item-${index}`}>
          {child}
        </Grid>
      ))}
  </Grid>
);

export default Spacer;
