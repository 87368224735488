import { useField } from 'formik';
import React from 'react';
import Spacer from '../spacer/Spacer';

export type ConditionWrapperProps = {
  dependency: string;
  condition: (value: any) => boolean;
};

const ConditionWrapper: React.FC<ConditionWrapperProps> = ({
  children,
  dependency,
  condition,
}) => {
  return condition(dependency) && <Spacer direction="column">{children}</Spacer>;
};

export default ConditionWrapper;
