import React from "react";
import AboutYou from "./pages/aboutYou/AboutYou";
import ProjectInfo from "../../components/projectInfo/ProjectInfo";
import YourName from "./pages/YourName";
import Contact from "./pages/Contact";
import FederalService from "./pages/FederalService";
import Start from "./pages/Start";
import SelectiveService from "./pages/SelectiveService/SelectiveService";
import MilitaryService from "./pages/MilitaryService/MilitaryService";
import BackgroundInfo from "./pages/backgroundInfo/BackgroundInfo";
import AddititionalInfo from "./pages/additionalInfo/AdditionalInfo";
import PersonalInfo from "./pages/personalInfo/PersonalInfo";
import VAInfo from "./pages/vaInfo/VAInfo";
import PreventAssaultInfo from "./pages/preventAssaultInfo/PreventAssaultInfo";
import CredentialVerification from "./pages/credentialVerification/CredentialVerification";
import DriversLicenseUpload from "./pages/imagePDF/driversLicense/DriversLicenseUpload";
import SSNCardUpload from "./pages/imagePDF/ssnCard/SSNCardUpload";

export interface CompProps {
  questions?: any[];
  sections?: any[];
}
export type PageConfigs = {
  route: string;
  formGroups: string[];
  buttonText: string;
  Component?: React.FC<CompProps>;
  key?: string;
}[];

const pageConfigs: PageConfigs = [
  {
    route: "start",
    formGroups: [],
    buttonText: "Begin",
    Component: Start,
    key: "0",
  },
  {
    route: "your-name",
    formGroups: ["fullName", "otherNames"],
    buttonText: "Continue",
    Component: YourName,
    key: "a",
  },
  {
    route: "contact-info",
    formGroups: ["contactInfo", "address"],
    buttonText: "Continue",
    Component: Contact,
    key: "b",
  },
  {
    route: "about-you",
    formGroups: ["aboutYou"],
    buttonText: "Continue",
    Component: AboutYou,
    key: "c",
  },
  {
    route: "personal-info",
    formGroups: ["personalInfo"],
    buttonText: "Continue",
    Component: PersonalInfo,
    key: "d",
  },
  {
    route: "project-info",
    formGroups: ["projectInfo"],
    buttonText: "Continue",
    Component: ProjectInfo,
    key: "e",
  },
  {
    route: "federal-service",
    formGroups: ["federalService"],
    buttonText: "Continue",
    Component: FederalService,
    key: "f",
  },
  {
    route: "selective-service",
    formGroups: ["selectiveService"],
    buttonText: "Continue",
    Component: SelectiveService,
    key: "g",
  },
  {
    route: "military-service",
    formGroups: ["militaryService"],
    buttonText: "Continue",
    Component: MilitaryService,
    key: "h",
  },
  {
    route: "background-info",
    formGroups: ["backgroundInfo"],
    buttonText: "Continue",
    Component: BackgroundInfo,
    key: "i",
  },
  {
    route: "additional-info",
    formGroups: ["additionalInfo"],
    buttonText: "Continue",
    Component: AddititionalInfo,
    key: "j",
  },
  {
    route: "va-info",
    formGroups: ["vaInfo"],
    buttonText: "Continue",
    Component: VAInfo,
    key: "k",
  },
  {
    route: "prevent-assault-info",
    formGroups: ["preventAssaultInfo"],
    buttonText: "Continue",
    Component: PreventAssaultInfo,
    key: "l",
  },
  {
    route: "credential-verification",
    formGroups: ["credentialVerification"],
    buttonText: "Continue",
    Component: CredentialVerification,
    key: "m",
  },
  {
    route: "drivers-license",
    formGroups: ["driversLicense"],
    buttonText: "Continue",
    Component: DriversLicenseUpload,
    key: "n",
  },
  {
    route: "ssn-card",
    formGroups: ["ssnCard"],
    buttonText: "Continue",
    Component: SSNCardUpload,
    key: "o",
  },
];

export default pageConfigs;
