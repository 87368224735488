import { AxiosResponse } from 'axios';

export const buildInputName = (
  formGroup: string
): ((name: string | number) => string) => (name) => `${formGroup}.${name}`;

export type DownloadFileArgs = { filename: string; type: string };

export const downloadFile = ({
  filename,
  type,
}: DownloadFileArgs): ((response: AxiosResponse<any>) => void) => (
  response
) => {
  const url = window.URL.createObjectURL(new Blob([response.data], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.click();
};

export const buildFormBody = (obj: { [key: string]: string }): string =>
  Object.keys(obj)
    .map((k) => `${k}=${obj[k]}`)
    .join('&');
