import { Button, createStyles, Grid, makeStyles, Theme, Menu, MenuItem, Typography } from "@material-ui/core";
import React from 'react';
import '../../App.css';
import logo from "../../aptive_logo_white.png";
import { AccountCircleOutlined, ArrowDropDown } from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import jwt from 'jwt-decode';

type NavbarProps = {
    isUserLogged: boolean;
    setUserLogged: React.Dispatch<any>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nav: {
            float: "right",
            fontSize: "15",
            marginTop: theme.spacing(4)
        },
        icon:{
            color: "white",
            height: "30px",
            width:"30px"
        },
        margin:{
            marginRight: theme.spacing(0.5)
        }
    }));

const Navbar: React.FC<NavbarProps> = ({ isUserLogged, setUserLogged }) => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();
    
    const user: any = localStorage.getItem('access-token') ? jwt(localStorage.getItem('access-token')) : {};

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangePassword = () => {
        if (window.location.href.includes("admin")) {
            history.push("/admin/change-password")
        }
        else {
            history.push("/change-password")
        }
        setAnchorEl(null);
    };

    const logout: () => Promise<void> = async () => {
        setUserLogged(false);
        setAnchorEl(null);
        localStorage.removeItem("access-token");
        history.push('/login');
    }

    return (
        <React.Fragment>
            <header className="App-header">
                <Grid>
                    <img src={logo} className="App-logo" alt="logo" />
                    {isUserLogged ?
                            <div className={classes.nav}>
                                <Button
                                disableRipple
                                    style={{ color: "white", }}
                                    id="basic-button"
                                    onClick={handleClick}
                                >
                                    <Typography variant={"body1"} className={classes.margin}>{user.user_name}</Typography>
                                    <AccountCircleOutlined className={classes.icon}/>
                                    <ArrowDropDown className={classes.icon}/>
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                                >
                                    <MenuItem onClick={() => onChangePassword()}>Change Password</MenuItem>
                                    <MenuItem onClick={() => logout()}>Logout</MenuItem>
                                </Menu>
                       
                        </div>
                        : null}
                </Grid>
            </header>
        </React.Fragment >
    );
}

export default Navbar;
