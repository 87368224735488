import React, { ChangeEvent, ReactNode } from "react";
import axios from "../../../axios";
import { Formik } from "formik";
import { Typography, Divider, TextField } from "@material-ui/core";
import Select, { SelectOption } from "../../../inputs/select/Select";
import Spacer from "../../../components/spacer/Spacer";
import projectSchema, { ProjectSchema } from "./Projects.schema";
import ProjectInput from "./ProjectInput";
import { AuthenticatedProps } from "../Common";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";

type ProjectOption = { id: string; name: string };
type Prepopulation = {
  [fieldName: string]: string;
};
type FormDefinition = {
  wetSignatureRequired: boolean;
  prepopulation: Prepopulation;
};
type FormsDefinition = {
  [formName: string]: FormDefinition;
};
type Project = {
  name: string;
  companyName: string;
  stationId: string;
  active: boolean;
  forms: FormsDefinition;
};

const projectOptionToOption = (project: ProjectOption): SelectOption => ({
  label: project.name,
  value: project.id,
});

const actionOptions = [
  { label: "Create new project", value: "create" },
  { label: "Update existing project", value: "update" },
];

const notify = () => toast("Wow so easy!");

type OnChangeHandler = (
  event: ChangeEvent<{ name?: string; value: unknown }>,
  child: ReactNode
) => void;

const formatPayload = ({
  name,
  companyName,
  stationId,
  forms,
  active = true,
}: ProjectSchema): Project => {
  const formsData = forms.reduce(
    (formsObj, { form, fields, wetSignatureRequired }) => {
      const prepopulation = fields.reduce(
        (acc, { key, value }) => ({
          ...acc,
          [key]: value,
        }),
        {}
      );
      return {
        ...formsObj,
        [form]: { wetSignatureRequired, prepopulation },
      };
    },
    {} as FormsDefinition
  );
  return {
    name,
    companyName,
    stationId,
    active,
    forms: formsData,
  };
};

const Projects: React.FC<AuthenticatedProps> = ({ getAccessToken, logout }) => {
  const [token, setToken] = React.useState<string>("");
  const [action, setAction] = React.useState<string>("");
  const [projects, setProjects] = React.useState<ProjectOption[]>([]);
  const [projectId, setProjectId] = React.useState<string>("");
  const [project, setProject] = React.useState<Project>();
  const history = useHistory();

  React.useEffect(() => {
    getAccessToken(["openid"]).then(setToken);
  }, [getAccessToken, setToken]);
  const headers = { Authorization: `bearer ${token}` };

  React.useEffect(() => {
    if (token !== "") {
      axios
        .get("projects", { headers })
        .then((response) => setProjects(response.data))
        .catch((error) => {
          console.log({ error });
        });
    }
  }, [setProjects, token]);

  const handleActionChange: OnChangeHandler = (event) => {
    const { value } = event.target;
    setAction(value as string);
  };

  const getProjectDefinition = (value) => {
    setProjectId(value as string);
    if (value !== undefined) {
      axios
        .get(`projects/${value}`, { headers })
        .then((response) => setProject(response.data));
    }
  };

  const initialValues = ({
    name,
    companyName,
    stationId,
    forms,
  }: Project): ProjectSchema => ({
    name,
    companyName,
    stationId,
    forms: Object.keys(forms).reduce((acc, form) => {
      const { prepopulation, wetSignatureRequired } = forms[form];
      const fields = Object.keys(prepopulation).reduce(
        (keyVals, key) => [...keyVals, { key, value: prepopulation[key] }],
        []
      );
      return [...acc, { form, fields, wetSignatureRequired }];
    }, []),
  });

  const createProject = (project: ProjectSchema) => {
    const data = formatPayload(project);
    axios
      .post(`projects`, data, { headers })
      .then((response) => {
        history.push({
          pathname: `/admin/projects/${response.data}`,
          state: { detail: "Created" },
        });
      })
      .catch(function (error) {
        if (error.response) {
          toast(error.response.data.detail);
        } else {
          toast(`Project creation unsuccessful`);
        }
      });
  };
  const updateProject = (project: ProjectSchema) => {
    const data = formatPayload(project);
    axios
      .put(`projects/${projectId}`, data, { headers })
      .then((response) => {
        history.push({
          pathname: `/admin/projects/${projectId}`,
          state: { detail: "Updated" },
        });
      })
      .catch(function (error) {
        if (error.response) {
          toast(error.response.data.detail);
        } else {
          toast(`Project creation unsuccessful`);
        }
      });
  };

  const onArchive = () => {
    if (project.active) {
      project.active = false;
    } else {
      project.active = true;
    }

    axios
      .put(`projects/${projectId}`, project, { headers })
      .then((response) => {
        history.push({
          pathname: `/admin/projects/${projectId}`,
          state: { detail: "Updated" },
        });
      })
      .catch(function (error) {
        if (error.response) {
          toast(error.response.data.detail);
        } else {
          toast(`Project update unsuccessful`);
        }
      });
  };
  return (
    <Spacer direction="column">
      <ToastContainer />
      <Typography variant="h5">Manage projects</Typography>
      <Select
        name="action"
        label="Select the action you want to perform"
        value={action}
        options={actionOptions}
        onChange={handleActionChange}
      />
      <Divider />
      {action === "update" && (
        <Spacer direction="column">
          <Autocomplete
            getOptionLabel={(option: SelectOption) => option.label}
            disablePortal
            id="project"
            options={projects.map(projectOptionToOption)}
            onChange={(event, newValue: SelectOption) => {
              getProjectDefinition(newValue.value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Project"
                variant="outlined"
              />
            )}
          />
          <Divider />
          {project && (
            <Formik
              validationSchema={projectSchema}
              initialValues={initialValues(project)}
              enableReinitialize
              onSubmit={updateProject}
            >
              {({ handleReset, handleChange }) => (
                <ProjectInput
                  handleChange={handleChange}
                  handleReset={handleReset}
                  action="update"
                  onArchive={onArchive}
                  archived={!project.active}
                />
              )}
            </Formik>
          )}
        </Spacer>
      )}
      {action === "create" && (
        <Formik
          validationSchema={projectSchema}
          initialValues={projectSchema.cast()}
          onSubmit={createProject}
        >
          {({ handleReset, handleChange }) => (
            <ProjectInput
              handleReset={handleReset}
              handleChange={handleChange}
              action="create"
            />
          )}
        </Formik>
      )}
    </Spacer>
  );
};

export default Projects;
