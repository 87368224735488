import * as Yup from "yup";

export const preventAssaultInfoSchemaDynamic = (questions) =>
  Yup.object({
    
    sexualAbuse: Yup.string()
    .default("")
    .required("This is required."),
    
    engageSexualActivity: Yup.string()
    .default("")
    .required("This is required."),

    victimConsent: Yup.string()
    .default("")
    .required("This is required."),

    civilAdminAdjudication: Yup.string()
    .default("")
    .required("This is required."),
});

