import { Button, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { AuthenticatedProps } from '../Common';
import axios from '../../../axios';
import { useLocation } from "react-router-dom";
import {Project} from "../projects/ViewProject";
import { SelectOption } from '../../../inputs/select/Select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
   main:{
       marginTop: theme.spacing(2),
    },
   info:{
      marginTop: theme.spacing(0.5),
   },
   element:{
    marginTop: theme.spacing(0.1),
   },
   heading:{
    fontWeight : "bold",
   },
   button:{
    marginTop: theme.spacing(5)
   }
}));

type User = { name: string; id: string; projectId?: string, phoneNumber: string; userEmail: string };

interface UserPreviewProps {
   user : any;
}

const UserPreview: React.FC<UserPreviewProps> = ({ user }) => {
   const classes = useStyles();
   return (
      <>
      <Typography  variant="h6">{`User Details`}</Typography>
      <Grid container className={classes.info}>
      <Grid container spacing={3}  className={classes.element}>
         <Grid item xs={12} sm={4} md={3}>
            <Typography className={classes.heading}>User Name</Typography>
         </Grid>
         <Grid item xs={12} sm={6} md={8}>
            <Typography>{user?.name}</Typography>
         </Grid>
      </Grid>
      <Grid container spacing={3}  className={classes.element}>
         <Grid item xs={12} sm={4} md={3}>
            <Typography className={classes.heading}>Phone Number</Typography>
         </Grid>
         <Grid item xs={12} sm={6} md={8}>
            <Typography>{user?.phoneNumber}</Typography>
         </Grid>
      </Grid>
      <Grid container spacing={3}  className={classes.element}>
         <Grid item xs={12} sm={4} md={3}>
            <Typography className={classes.heading}>Email</Typography>
         </Grid>
         <Grid item xs={12} sm={6} md={8}>
            <Typography>{user?.userEmail}</Typography>
         </Grid>
      </Grid>
   </Grid>
   </>
   )
}

const ViewUser: React.FC<AuthenticatedProps> = ({ getAccessToken, logout }) => {
    const classes = useStyles();
    const history = useHistory();
    const [token, setToken] = React.useState<string>();
    const [user, setUser] = React.useState<User>();
    const { userId }: any = useParams();
    const status = useLocation();
    const [project,setProject]= React.useState<Project>();
    const [forms,setForms]= React.useState<SelectOption[]>();

   const headers = { Authorization: `bearer ${token}` };
   
   React.useEffect(() => {
        getAccessToken(['openid']).then(setToken);
    }, [getAccessToken, setToken]);

   React.useEffect(() => {
      getUser();
    }, [token,userId]);
   
   const getUser = () => {
         if (token && userId) {
            axios
             .get(`users/${userId}`, { headers })
             .then((response) => {
                setUser(response.data);
                if (response.data.projectId !== undefined) {
                  axios
                   .get(`projects/${response.data.projectId}`, { headers })
                   .then((response) => {
                      setProject(response.data);
                      axios.get('configs/forms').then((formData) => {
                        const selectedForms: string[] = Object.keys(response.data?.forms);
                        const filteredFormNames: SelectOption[] = formData?.data.filter((form)=> selectedForms.indexOf(form?.value.toString())>=0 );
                        setForms(filteredFormNames);
                      }); 
                  });
               }
            });
         }
   };
    return (
        <>
        <Typography  variant="h5">{`User ${status.state.detail}`}</Typography>
        <Grid container className={classes.main}>
            <Grid container spacing={5} className={classes.element}>
               <Grid item xs={12} sm={6} md={3}>
                  <Typography className={classes.heading}>User Name</Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={8}>
                  <Typography>{user?.name}</Typography>
               </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.element}>
               <Grid item xs={12} sm={6} md={3}>
                  <Typography className={classes.heading}>Phone Number</Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={8}>
                  <Typography>{user?.phoneNumber}</Typography>
               </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.element}>
               <Grid item xs={12} sm={6} md={3}>
                  <Typography className={classes.heading}>Email</Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={8}>
                  <Typography>{user?.userEmail}</Typography>
               </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.element}>
                 <Grid item xs={12} sm={6} md={3}>
                   <Typography className={classes.heading}>Project</Typography>
                 </Grid>
                 <Grid item xs={12} sm={6} md={8}>
                   {user?.projectId ? project?.name : "-"}
                 </Grid>
            </Grid>
            { (user?.projectId && project) &&
             <>
               <Grid container spacing={5} className={classes.element}>
                 <Grid item xs={12} sm={6} md={3}>
                   <Typography className={classes.heading}>Company Name</Typography>
                 </Grid>
                 <Grid item xs={12} sm={6} md={8}>
                   {project?.companyName}
                 </Grid>
              </Grid>
               <Grid container spacing={5} className={classes.element}>
                 <Grid item xs={12} sm={6} md={3}>
                   <Typography className={classes.heading}>Station</Typography>
                 </Grid>
                 <Grid item xs={12} sm={6} md={8}>
                   {project?.stationId}
                 </Grid>
              </Grid>
               <Grid container spacing={5} className={classes.element}>
                  <Grid item xs={12} sm={6} md={3}>
                     <Typography className={classes.heading}>Forms</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                     {forms ? forms.map((form: SelectOption)=>
                      <Typography className={classes.element} key={form.value}>{form?.label}</Typography>
                     ) : "-"}
                  </Grid>
              </Grid>
            </> }
            <Button className={classes.button} color="primary" variant="outlined" onClick={()=> history.push("/admin/users")}>
            Back to Users
           </Button>
        </Grid>
        </>
    )
}

export default ViewUser;
export { UserPreview };