import React from "react";
import Spacer from "../spacer/Spacer";
import Textbox from "../../inputs/textbox/Textbox";
import { Divider, Typography } from "@material-ui/core";
import { buildInputName } from "../../utils/Utils";
import { CompProps } from "../../modules/onboardingForm/PageConfigs";
import DateInput from "../../inputs/dateInput/DateInput";

const inputName = buildInputName("projectInfo");

const ProjectInfo: React.FC<CompProps> = ({ questions, sections }) =>
  sections.includes("project-info") && (
    <Spacer direction="column">
      <Typography variant="h5">Project Information</Typography>
      <Typography variant="subtitle1">
        Please enter the following information about the project you are
        joining.
      </Typography>
      {questions.includes("positionTitle") && (
        <Textbox
          required={true}
          name={inputName("positionTitle")}
          label="Position Title"
        />
      )}
      {questions.includes("contractorStartDate") && (
        <DateInput
          required={true}
          name={inputName("contractorStartDate")}
          label="Contractor start date"
        />
      )}
      {questions.includes("contractEndDate") && (
        <DateInput
          required={true}
          name={inputName("contractEndDate")}
          label="Contract end date"
        />
      )}
      <Divider />
    </Spacer>
  );

export default ProjectInfo;
