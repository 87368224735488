import * as Yup from "yup";

const personalInfoSchema = Yup.object({
  heightFeet: Yup.string().default(""),
  heightInches: Yup.string().default(""),
  weight: Yup.string().default(""),
  eyeColor: Yup.string().default(""),
  hairColor: Yup.string().default(""),
  gender: Yup.string().default("").required("This response is required."),
  race: Yup.string().default(""),
  maritalStatus: Yup.string().default("")
});

export const personalInfoSchemaDynamic = (questions) =>
  Yup.object({
    heightFeet: Yup.string().default(""),
    heightInches: Yup.string().default(""),
    weight: Yup.string().default(""),
    eyeColor: Yup.string().default(""),
    hairColor: Yup.string().default(""),
    gender: Yup.string().when("check", {
      is: () => questions.includes("gender"),
      then: Yup.string().default("").required("This response is required."),
      otherwise: Yup.string().default(""),
    }),
    race: Yup.string().default(""),
    maritalStatus: Yup.string().default("")
  });

export default personalInfoSchema;
