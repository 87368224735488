import axios from "axios";
import jwt from 'jwt-decode';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

function redirect() {
    localStorage.setItem('expiry-message',"Session expired, please login again.")
    localStorage.removeItem('access-token')
    const port = window.location.port !== "" ? ":" + window.location.port : "";
    const str = window.location.protocol + "//" + window.location.hostname + port;
    window.location.href = str + "/login";
}

instance.interceptors.request.use(request => {
    const headers: any = localStorage.getItem('access-token') ? jwt(localStorage.getItem('access-token')) : {}
    const expiryDate = headers.exp ? new Date(headers.exp * 1000) : ""
    const currentDate = new Date();
    if (localStorage.getItem('access-token') && currentDate > expiryDate) {
        redirect();
        return;
    }
    return request
}, error => {
    const headers: any = localStorage.getItem('access-token') ? jwt(localStorage.getItem('access-token')) : {}
    const expiryDate = headers.exp ? new Date(headers.exp * 1000) : ""
    const currentDate = new Date();
    if (currentDate > expiryDate) {
        redirect();
        return;
    }
    return error
})

instance.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    if (err.response.status === 403) {
        redirect();
        return;
    }
    return Promise.reject(err)
})

export default instance;
