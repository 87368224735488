import React from 'react';
import { useField } from 'formik';
import { TextField, InputBaseComponentProps } from '@material-ui/core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { TextboxProps } from '../textbox/Textbox';
import DisabledFieldsContext from '../../components/disabledFieldsContext/DisabledFieldsContext';

export const isValidDate = (value: string): boolean =>
  value.replace(/\D/g, '').length < 8
    ? false
    : moment(value, 'MM-DD-YYYY').isValid();

const MaskedInput: React.FC<InputBaseComponentProps> = (props) => {
  return <InputMask {...props} mask="99/99/9999" maskChar=" "/>;
};

const DateInput: React.FC<TextboxProps> = ({ name, label, required=false, ...props }) => {
  const [field, meta] = useField(name);
  const disabled = React.useContext(DisabledFieldsContext).includes(name);
  const hasError = !!meta.touched && !!meta.error;
  return (
    <TextField
      required={required}
      {...props}
      fullWidth
       id={name}
      label={label}
      variant="outlined"
      {...field}
      error={hasError}
      disabled={disabled}
      helperText={"Valid Date format: MM/DD/YYYY. " + (meta.touched && meta.error ? meta.touched && meta.error : "")}
      InputProps={{ inputComponent: MaskedInput, placeholder:"MM/DD/YYYY" }}
      placeholder={"DD/MM/YYYY"}
    />
  );
};

export const DateInputField: React.FC<TextboxProps> = ({ name, label, required=false, ...props }) => {
  return (
    <TextField
      required={required}
      {...props}
      id={name}
      label={label}
      type="date"
      variant="outlined"
      helperText={"Valid Date format: MM/DD/YYYY. "}
      //InputProps={{ placeholder:"MM/DD/YYYY", inputComponent: MaskedInput }}
      InputLabelProps={{shrink: true}}
      placeholder={"MM/DD/YYYY"}
    />
  );
};

export default DateInput;

