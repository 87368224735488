import { FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { useField } from 'formik';
import React from 'react';
import DisabledFieldsContext from '../../components/disabledFieldsContext/DisabledFieldsContext';

export type CheckboxProps = {
  name: string;
  label?: string;
  required? : boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({ name, label, required = false }) => {
  const [field, meta] = useField<boolean>({ name, type: 'checkbox' });
  const hasError = !!meta.touched && !!meta.error;
  return (
    <><FormControl required={required} error={hasError}>
      <FormControlLabel
        control={<MuiCheckbox {...field} color="primary" />}
        label={label} />
      {hasError && (
        <FormHelperText error style={{ margin: '0px 14px' }}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl><br /></>
  );
};

export default Checkbox;
