import React from 'react';
import axios from '../../../axios';
import Spacer from '../../../components/spacer/Spacer';
import { Typography, Link, Button, withStyles, CircularProgress } from '@material-ui/core';
import { downloadFile } from '../../../utils/Utils';
import { AuthenticatedProps } from '../Common';
import { Table, TableBody, TableRow, TableCell, TableContainer} from '@material-ui/core';
import MuiTableHead from "@material-ui/core/TableHead";
import { css } from "@emotion/react";

import ClipLoader from "react-spinners/ClipLoader";


type User = { id: string; name: string };

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;

const Completed: React.FC<AuthenticatedProps> = ({
  getAccessToken,
  logout,
}) => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState<string>('');
  const [submissions, setSubmissions] = React.useState<string[]>([]);
  const [userId, setUserId] = React.useState<string>('');
  const [downloadLoading, setDownloadLoading] = React.useState<any>([]);

  React.useEffect(() => {
    getAccessToken(['openid']).then(setToken);
  }, [getAccessToken, setToken]);
  const headers = { Authorization: `bearer ${token}` };

  const downloadAll = (submission: string): ((event: any) => void) => async (
    event
  ) => {
    const downloadArr= [...downloadLoading, submission]
    setDownloadLoading(downloadArr);
    event.preventDefault();
    await axios
    .get(`submissions`, { headers, params: { user_id: submission } })
    .then((response) => {
      setSubmissions(response.data);
      axios
        .post(
          `submissions/download`,
          { keys: response.data },
          {
            headers,
            responseType: 'arraybuffer',
          }
        )
        .then(
          downloadFile({
            type: 'octet/stream',
            filename: `${users
              .find((user) => submission === user.id)
              .name.replace(' ', '_')}_documents.zip`,
          })
        ).finally(()=> {
          setDownloadLoading(downloadLoading.filter((ele)=> ele===submission));
        });
      }
    )
  };

  const moveToCompleted = (userId: string): ((event: any) => void) => (
    event
  ) => {
    event.preventDefault();
    const user_id = userId;
    const formStatus = "COMPLETED";
    console.log("userId::", userId);
    axios
      .put(`users/${user_id}`, { formStatus }, { headers })
      .then(() => 
        axios.get('users', { headers, params: { form_status: "COMPLETED"} })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log({ error });
        }));
  };
  
  const purgeUser = (userId: string): ((event: any) => void) => (
    event
  ) => {
    console.log("userId::", userId);
    axios
      .put(`users/${userId}/purge-data`,{},{ headers })
      .then(() => 
           console.log("USER PURGED")
      ).then(()=> getUsers());
    
  };

  const getUsers = () =>{
    if (token !== '') {
      var params = new URLSearchParams();
      params.append("form_status", "COMPLETED");
      axios
        .get('users', { headers, params: params })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }
  React.useEffect(() => { 
    getUsers();
  }, [setUsers, token ]);

  const TableHead = withStyles({
    root: {
      border: "black",
      padding: "1px",
      verticalAlign: 'top',
      width: '70%',
      background: 'linear-gradient(to right bottom, #223065, #FFFFFF)',    
    }
  })(MuiTableHead);

  return (
    <Spacer direction="column">
      <Typography variant="h5">Completed documents</Typography>
      <ClipLoader color={"FFFFF"} loading={loading} css={override} size={100} />
      <TableContainer>
          <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">User Name</TableCell>
              <TableCell align="left">Project</TableCell>
              <TableCell align="left">Download</TableCell>
              <TableCell align="left">Purge User Data</TableCell>
              <TableCell align="left">Completed date</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
            {users.map((element, j) => 
                <TableRow key={j}>
                  <TableCell key={element["name"]+j}>{element["name"]}</TableCell>
                  <TableCell key={element["project_name"]+j}>{element["project_name"]}</TableCell>
                  <TableCell key={element["Nr"]+j}>
                    <Link onClick={downloadAll(element["id"])} href="#">
                      <Typography>Download all documents {downloadLoading.includes(element["id"]) && <CircularProgress size={15} thickness={3}/>} </Typography>
                    </Link>
                  </TableCell>
                  <TableCell key={`user_${element["Nr"]}_${j}`}>
                    <Button variant="contained" style={{ float: 'left' }} onClick={purgeUser(element["id"])}>
                      Purge user data
                    </Button>
                  </TableCell>
                  <TableCell >{element["timestamp"]}</TableCell>
                  
                </TableRow>
            )}
            </TableBody>
          </Table>
        </TableContainer>
    </Spacer>
  );
};

export default Completed;
