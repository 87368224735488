import React from "react";
import Spacer from "../spacer/Spacer";
import Textbox from "../../inputs/textbox/Textbox";
import { Divider, Typography } from "@material-ui/core";
import { buildInputName } from "../../utils/Utils";
import ZipInput from "../../inputs/zipInput/ZipInput";
import { CompProps } from "../../modules/onboardingForm/PageConfigs";

const inputName = buildInputName("address");

const Address: React.FC<CompProps> = ({ questions, sections }) => (
  <Spacer direction="column">
    <>
      <Typography variant="h5">Home Address</Typography>
      <Typography variant="subtitle1">
        Please enter your current address.
      </Typography>
    </>

    {questions.includes("addressLineOne") && (
      <Textbox
        required={true}
        name={inputName("addressLineOne")}
        label="Address line one"
      />
    )}
    {questions.includes("addressLineTwo") && (
      <Textbox name={inputName("addressLineTwo")} label="Address line two" />
    )}

    <Spacer items={3}>
      {questions.includes("city") && (
        <Textbox required={true} name={inputName("city")} label="City" />
      )}
      {questions.includes("state") && (
        <Textbox required={true} name={inputName("state")} label="State" />
      )}
      {questions.includes("zip") && (
        <ZipInput required={true} name={inputName("zip")} label="Zip" />
      )}
    </Spacer>
    <Divider />
  </Spacer>
);

export default Address;
