import React from "react";
import { useField } from "formik";
import { TextField, InputBaseComponentProps, InputAdornment, IconButton } from "@material-ui/core";
import InputMask from "react-input-mask";
import { TextboxProps } from "../textbox/Textbox";
import DisabledFieldsContext from "../../components/disabledFieldsContext/DisabledFieldsContext";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export const isValidSsnL4 = (value: string): boolean =>
  value.replace(/\D/g, "").length === 4;

const MaskedInput: React.FC<InputBaseComponentProps> = (props) => {
  return <InputMask {...props} mask="9999" maskChar=" "/>;
};
const MaskedInput2: React.FC<InputBaseComponentProps> = (props) => {
  return <InputMask {...props} mask="9999" maskChar=""/>;
};

const SocialSecurityLast4Input: React.FC<TextboxProps> = ({ name, label,required = false, ...props }) => {
  const [field, meta] = useField(name);
  const hasError = !!meta.touched && !!meta.error;
  const disabled = React.useContext(DisabledFieldsContext).includes(name);
  const [showPassword, setShowPassword]= React.useState<boolean>(false);

  return (
    <TextField
      required={required}
      {...props}
      fullWidth
      id={name}
      label={label}
      variant="outlined"
      {...field}
      error={hasError}
      disabled={disabled}
      helperText={meta.touched && meta.error}
      type={showPassword ? "text" : "password"}
      InputProps={{
         inputComponent: showPassword ? MaskedInput : MaskedInput2,
         endAdornment: (
             <InputAdornment position="end">
               <IconButton
                 aria-label="toggle password visibility"
                 onClick={()=> setShowPassword(!showPassword)}
               >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
         )
      }}
    />
  );
};

export default SocialSecurityLast4Input;
