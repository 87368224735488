import { Typography, Divider, Button } from '@material-ui/core';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import { AxiosResponse } from 'axios';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { string } from 'yup';
import axios from '../../axios';
import Spacer from '../../components/spacer/Spacer';
import FileUpload, {
  FileUploadState,
} from '../../inputs/fileUpload/FileUpload';

type RouteMatchProps = {
  id: string;
};

const Uploads: React.FC = () => {
  const [requiredForms, setRequiredForms] = React.useState([]);
  const [loading, setLoading] = React.useState();
  const { push } = useHistory();
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('access-token')}`,
  };
  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>(`/form/:id/sign/upload`);

  React.useEffect(() => {
    axios
      .get(`forms/uploads/${id}/required`, { headers })
      .then((response) => setRequiredForms(response.data));
  }, [id]);

  const upload = (item: {
    name: string;
    file: File;
  }): Promise<AxiosResponse<string>> => {
    const { file, name } = item;
    const formData = new FormData();
    formData.append('file', file);
    console.log({ formData });
    return axios.post<string>(`forms/uploads/${id}/${name}`, formData, {
      headers,
    });
  };

  const onFileUpload = (state: FileUploadState): (() => void) => () => {
    const files = Object.keys(state).map((k) => ({ name: k, file: state[k] }));
    Promise.all(files.map(upload))
      .then(() => axios.delete(`drafts/${id}`, { headers }))
      .then(() => push('/complete'));
  };

  const fileUploadProps = { requiredForms, onFileUpload, id};

  return (
    <div style={{ width: '35%' }}>
      <Spacer direction="column">
        <Typography variant="h5">Upload wet signed forms</Typography>
        <Typography variant="subtitle1">
          Some forms require wet signatures. Print out your completed copy of
          each form below and sign in ink. Then, scan each signed form as a pdf
          and upload below. When you have uploaded all forms, press submit to
          finish.
        </Typography>
        <Divider />
        <FileUpload {...fileUploadProps} />
      </Spacer>
    </div>
  );
};

export default Uploads;
