import React from "react";
import Spacer from "../../../../components/spacer/Spacer";
import Textbox from "../../../../inputs/textbox/Textbox";
import { Divider, Typography } from "@material-ui/core";
import { buildInputName } from "../../../../utils/Utils";
import RadioButton from "../../../../inputs/radioButton/RadioButton";
import SocialSecurityInput from "../../../../inputs/socialSecurityInput/SocialSecurityInput";
import SocialSecurityLast4Input from "../../../../inputs/socialSecurityLast4Input/SocialSecurityLast4Input";
import DateInput from "../../../../inputs/dateInput/DateInput";
import CityStateCountry from "../../../../components/cityStateCountry/CityStateCountry";
import ConditionalWrapper from "../../../../components/conditionalWrapper/ConditionalWrapper";
import { CompProps } from "../../PageConfigs";

const inputName = buildInputName("aboutYou");

const citizenshipOptions = [
  { label: "US Citizen", value: "true" },
  { label: "Naturalized Citizen", value: "naturalized" },
  { label: "Non-Citizen", value: "false" },
];

const AboutYou: React.FC<CompProps> = ({ questions, sections }) => (
  <Spacer direction="column">
    <Typography variant="h5">About you</Typography>
    <Typography variant="subtitle1">
      Please enter the following personal identification information.
    </Typography>
    {sections.includes("birth") && (
      <Spacer direction="column">
        {questions.includes("dateOfBirth") && (
          <DateInput
            required={true}
            name={inputName("dateOfBirth")}
            label="Date of birth"
          />
        )}
        {questions.includes("placeOfBirthCity") && (
          <Spacer direction="column">
            {" "}
            <Typography variant="subtitle1">
              What is your place of birth?
            </Typography>
            <CityStateCountry formGroup={inputName("placeOfBirth")} />
          </Spacer>
        )}
      </Spacer>
    )}
    {sections.includes("citizen") && questions.includes("citizen") && (
      <>
        <RadioButton
          required={true}
          name={inputName("citizen")}
          label="Citizenship"
          options={citizenshipOptions}
        />
        {questions.includes("citizenship") && (
          <ConditionalWrapper
            dependency={inputName("citizen")}
            condition={(value) => value === "false"}
          >
            <Textbox
              required={true}
              name={inputName("citizenship")}
              label="Country of citizenship"
            />
          </ConditionalWrapper>
        )}
      </>
    )}

    {sections.includes("ssn") && questions.includes("ssnl4") && (
      <SocialSecurityLast4Input
        required={true}
        name={inputName("ssnl4")}
        label="Social security last 4 digits"
      />
    )}

    {sections.includes("ssn") && questions.includes("ssn") && (
      <SocialSecurityInput
        required={true}
        name={inputName("ssn")}
        label="Social security number"
      />
    )}

   {sections.includes("identification") && questions.includes("driversLicenseNumber") && (
      <><Textbox required={true} name={inputName("driversLicenseNumber")} label="Driver's License Number" />
      <br/><br/>
      <Textbox required={true} name={inputName("driversLicenseState")} label="Driver's License Issued State" /></>
    )}

    
    <Divider />
  </Spacer>
);

export default AboutYou;
