import * as Yup from "yup";
import additionalExplanationSchema from "../../../../components/additionalExplanation/AdditionalExplanation.schema";

const additionalInfoSchema = Yup.object({
  ...additionalExplanationSchema("relatives"),
  ...additionalExplanationSchema("benefits"),
  // benefits: Yup.string().default("").required("This response is required."),
});

export const additionalInfoSchemaDynamic = (questions) => {
  let addQuestionSchemas = {};
  const arr = ["relatives", "benefits"];
  arr.forEach((ques: string) => {
    if (questions.includes(ques)) {
      addQuestionSchemas = {
        ...addQuestionSchemas,
        ...additionalExplanationSchema(ques),
      };
    }
  });

  return Yup.object({
    ...addQuestionSchemas,
  });
};
export default additionalInfoSchema;
