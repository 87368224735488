import * as Yup from "yup";
import additionalExplanationSchema from "../../../../components/additionalExplanation/AdditionalExplanation.schema";

const backgroundInfoSchema = Yup.object({
  ...additionalExplanationSchema("criminalActivity"),
  ...additionalExplanationSchema("courtMartial"),
  ...additionalExplanationSchema("underCharges"),
  ...additionalExplanationSchema("fired"),
  ...additionalExplanationSchema("delinquent"),
});

export const backgroundInfoSchemaDynamic = (questions) => {
  let backgroundQuestions = {};
  const arr = [
    "criminalActivity",
    "courtMartial",
    "underCharges",
    "fired",
    "delinquent",
  ];

  arr.forEach((ques: string) => {
    if (questions.includes(ques)) {
      backgroundQuestions = {
        ...backgroundQuestions,
        ...additionalExplanationSchema(ques),
      };
    }
  });
  console.log(backgroundQuestions);
  return Yup.object({
    ...backgroundQuestions,
  });
};
export default backgroundInfoSchema;
