import React from "react";

import { useField } from "formik";
import Spacer from "../spacer/Spacer";
import { Typography } from "@material-ui/core";
import Textarea from "../../inputs/textarea/Textarea";
import BooleanRadio from "../../inputs/booleanRadio/BooleanRadio";

export type AdditionalExplanationProps = {
  name: string;
  question: string;
  explanation: string;
  showExplanation?: (value: string) => boolean;
  id: string;
};

const defaultCondition = (value: string) => value === "true";

const AdditionalExplanation: React.FC<AdditionalExplanationProps> = ({
  name,
  question,
  explanation,
  id,
  showExplanation = defaultCondition,
}) => {
  const [{ value }] = useField(name);
  return (
    <Spacer direction="column">
      <BooleanRadio required={true} label={question} name={name} />
      {showExplanation(value) && (
        <Spacer direction="column">
          <Typography variant="subtitle1">{explanation}</Typography>
          <Textarea
            required={true}
            name={`${name}Explanation`}
            label="Explanation"
          />
        </Spacer>
      )}
    </Spacer>
  );
};

export default AdditionalExplanation;
