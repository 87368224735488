import { Divider, Typography } from "@material-ui/core";
import React from "react";
import ConditionalWrapper from "../../../../components/conditionalWrapper/ConditionalWrapper";
import Spacer from "../../../../components/spacer/Spacer";
import BooleanRadio from "../../../../inputs/booleanRadio/BooleanRadio";
import DateInput from "../../../../inputs/dateInput/DateInput";
import { buildInputName } from "../../../../utils/Utils";
import { CompProps } from "../../PageConfigs";

const inputName = buildInputName("vaInfo");

const VAInfo: React.FC<CompProps> = ({ questions, sections }) => {
  return (
    sections.includes("va-info") && (
      <Spacer direction="column">
        <Typography variant="h5">Fingerprinting information</Typography>
        {questions.includes("fingerprintsOnFile") && (
          <BooleanRadio
            required={true}
            label={
              "Do you have fingerprints on file with the VA less than 120 days old?"
            }
            name={inputName("fingerprintsOnFile")}
          />
        )}

        <>
          <ConditionalWrapper
            dependency={inputName("fingerprintsOnFile")}
            condition={(value) => value === "true"}
          >
            <Typography variant="subtitle1">
              What was the date you were fingerprinted?
            </Typography>
            <DateInput
              required={true}
              name={inputName("fingerprintsDate")}
              label="Date"
            />
          </ConditionalWrapper>
          <Divider />
        </>

        {questions.includes("currentBadge") && (
          <BooleanRadio
            required={true}
            label={"Do you have a current VA badge?"}
            name={inputName("currentBadge")}
          />
        )}

        <>
          <ConditionalWrapper
            dependency={inputName("currentBadge")}
            condition={(value) => value === "false"}
          >
            <BooleanRadio
              required={true}
              label={"Have you had a previous VA badge?"}
              name={inputName("previousBadge")}
            />
          </ConditionalWrapper>
          <Divider />
        </>

        {questions.includes("currentEmail") && (
          <BooleanRadio
            required={true}
            label={"Do you have a current VA email/IT access?"}
            name={inputName("currentEmail")}
          />
        )}

        <>
          <ConditionalWrapper
            dependency={inputName("currentEmail")}
            condition={(value) => value === "false"}
          >
            <BooleanRadio
              required={true}
              label={"Have you previously had a VA email/IT access?"}
              name={inputName("previousEmail")}
            />
          </ConditionalWrapper>
          <Divider />
        </>
      </Spacer>
    )
  );
};

export default VAInfo;
