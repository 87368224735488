import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import { useField } from "formik";

export type RadioButtonOption = {
  label: string;
  value: string | number | boolean;
};

type RadioButtonProps = {
  name: string;
  options: RadioButtonOption[];
  label?: string;
  required?: boolean;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  name,
  options,
  required = false
}: RadioButtonProps) => {
  const [field, meta] = useField(name);
  const hasError = !!meta.touched && !!meta.error;
  return (
    <FormControl component="fieldset" error={hasError}>
      {label && <FormLabel  style={{color: "black", marginBottom:"5px", lineHeight: "1.75" }} required={required} component="legend">{label}</FormLabel>}
      <RadioGroup {...field}>
        {options.map((option) => (
          <FormControlLabel
            key={`option-${option.value}`}
            value={option.value}
            label={option.label}
            control={
              <Radio color="primary" checked={option.value === field.value} />
            }
          />
        ))}
        {hasError && (
          <FormHelperText error style={{ margin: "0px 14px" }}>
            {meta.error}
          </FormHelperText>
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
