import React from 'react';
import { useField } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';
import DisabledFieldsContext from '../../components/disabledFieldsContext/DisabledFieldsContext';

export type TextboxProps = Omit<
  TextFieldProps,
  'variant' | 'name' | 'label'
> & {
  name: string;
  label?: string;
};

const Textbox: React.FC<TextboxProps> = ({ name, label,required=false, ...props }) => {
  const [field, meta] = useField(name);
  const disabled = React.useContext(DisabledFieldsContext).includes(name);
  const hasError = !!meta.touched && !!meta.error;
  return (
    <TextField
      required={required}
      fullWidth
      id={name}
      label={label}
      variant="outlined"
      {...field}
      error={hasError}
      disabled={disabled}
      helperText={meta.touched && meta.error}
      {...props}
    />
  );
};

export default Textbox;
