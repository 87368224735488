import * as Yup from "yup";
import { isValidDate } from "../../inputs/dateInput/DateInput";

const projectInfoSchema = Yup.object({
  contractorStartDate: Yup.string()
    .default("")
    .required("Contractor start date is required.")
    .test("valid date", "Date entered must be valid.", isValidDate),
  contractEndDate: Yup.string()
    .default("")
    .required("Contract end date is required.")
    .test("valid date", "Date entered must be valid.", isValidDate),  
  positionTitle: Yup.string()
    .default("")
    .required("Position title is required."),
}).defined();

export const projectInfoSchemaDynamic = (questions) =>
  Yup.object({
    contractorStartDate: Yup.string().when("check", {
      is: () => questions.includes("contractorStartDate"),
      then: Yup.string()
        .default("")
        .required("Contractor start date is required.")
        .test("valid date", "Date entered must be valid.", isValidDate),
      otherwise: Yup.string().default(""),
    }),
    contractEndDate: Yup.string().when("check", {
      is: () => questions.includes("contractEndDate"),
      then: Yup.string()
        .default("")
        .required("Contract end date is required.")
        .test("valid date", "Date entered must be valid.", isValidDate),
      otherwise: Yup.string().default(""),
    }),
    positionTitle: Yup.string().when("check", {
      is: () => questions.includes("positionTitle"),
      then: Yup.string().default("").required("Position title is required."),
      otherwise: Yup.string().default(""),
    }),
    
  }).defined();

export type ProjectInfoSchema = Yup.InferType<typeof projectInfoSchema>;
export default projectInfoSchema;
