import React from 'react';
import { useField } from 'formik';
import { TextField, InputBaseComponentProps } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { TextboxProps } from '../textbox/Textbox';
import DisabledFieldsContext from '../../components/disabledFieldsContext/DisabledFieldsContext';

export const isValidZip = (value: string): boolean =>
  value.replace(/\D/g, '').length === 5;

const MaskedInput: React.FC<InputBaseComponentProps> = (props) => {
  return <InputMask {...props} mask="99999" maskChar=" " />;
};

const ZipInput: React.FC<TextboxProps> = ({ name, label, ...props }) => {
  const [field, meta] = useField(name);
  const disabled = React.useContext(DisabledFieldsContext).includes(name);
  const hasError = !!meta.touched && !!meta.error;
  return (
    <TextField
      {...props}
      fullWidth
      id={name}
      label={label}
      variant="outlined"
      {...field}
      error={hasError}
      disabled={disabled}
      helperText={meta.touched && meta.error}
      InputProps={{ inputComponent: MaskedInput }}
    />
  );
};

export default ZipInput;
