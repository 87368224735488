import * as Yup from "yup";

const cityStateCountrySchema = Yup.object({
  city: Yup.string().default("").required("City is required."),
  state: Yup.string().default("").required("State is required."),
  country: Yup.string().default("").required("Country is required."),
});

export const cityStateCountrySchemaDynamic = (questions) =>
  Yup.object({
    city: Yup.string().when("check", {
      is: () => questions.includes("placeOfBirthCity"),
      then: Yup.string().default("").required("City is required."),
      otherwise: Yup.string().default(""),
    }),
    state: Yup.string().when("check", {
      is: () => questions.includes("placeOfBirthState"),
      then: Yup.string().default("").required("State is required."),
      otherwise: Yup.string().default(""),
    }),
    country: Yup.string().when("check", {
      is: () => questions.includes("placeOfBirthCountry"),
      then: Yup.string().default("").required("Country is required."),
      otherwise: Yup.string().default(""),
    }),
  });

export default cityStateCountrySchema;
