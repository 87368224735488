import React from "react";
import { Form, Formik } from "formik";
import { Button } from "@material-ui/core";
import axios from "../../axios";
import * as Yup from "yup";
import Spacer from "../../components/spacer/Spacer";
import onboardingFormSchema, {
  OnboardingFormSchema,
  onboardingFormSchemaDynamic,
} from "./OnboardingForm.schema";
import { useHistory, useRouteMatch, Switch, Route } from "react-router";
import pageConfigs from "./PageConfigs";
import DisabledFieldsContext from "../../components/disabledFieldsContext/DisabledFieldsContext";
import StepperComponent from "./pages/stepper";
import Contact from "./pages/Contact";
import jwt from "jwt-decode";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;

function schemaBuilder<T>(
  schema: Yup.Schema<T>
): (formGroups: string[]) => Yup.ObjectSchema<Partial<T> | undefined> {
  return (formGroups) =>
    Yup.object(
      formGroups.reduce((acc, next) => {
        const nextSchema = Yup.reach(schema, next);
        return { ...acc, [next]: nextSchema };
      }, {} as Yup.ObjectSchemaDefinition<Partial<T>>)
    );
}

type RouteMatchProps = {
  id: string;
  page: string;
};

type DraftResponse = { draft: OnboardingFormSchema; prepopulated: boolean };

const prepopulatedFields = [
  "fullName.firstName",
  "fullName.lastName",
  "projectInfo.companyName",
];
const prepopulatedFields3 = [
  "fullName.firstName",
  "fullName.lastName",
  "contactInfo.dayPhone",
  "contactInfo.nightPhone",
  "contactInfo.email",
  "address.addressLineOne",
  "address.addressLineTwo",
  "address.city",
  "address.state",
  "address.zip",
  "aboutYou.dateOfBirth",
  "aboutYou.ssn",
  "projectInfo.positionTitle",
  "projectInfo.companyName",
];

const OnboardingForm: React.FC = () => {
  const { push } = useHistory();
  const {
    params: { id, page },
  } = useRouteMatch<RouteMatchProps>("/form/:id/:page");
  const user_id = jwt(localStorage.getItem("access-token"));
  const [projectConfigs, setProjectConfigs] = React.useState<string[]>([]);
  const [routeConfigs, setRouteConfigs] = React.useState<any>(pageConfigs);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userEmail, setUserEmail] = React.useState<any>("");

  const [initialValues, setInitialValues] =
    React.useState<OnboardingFormSchema>(onboardingFormSchema.cast());

  const [prepopulated, setPrepopulated] = React.useState<boolean>(false);

  const headers = {
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  };

  React.useEffect(() => {
    const getConfigs = async () => {
      setLoading(true);
      await axios
        .get(`users/${user_id["user_id"]}`, { headers })
        .then(async (res) => {
          setUserEmail(res?.data?.userEmail)
          await axios
            .get(`/configs/forms/questions/${res?.data?.projectId}`, {
              headers,
            })
            .then((configs) => {
              const page_custom_configs: any = [];
              const page_configs_res = pageConfigs.map((conf: any) => {
                if (Object.keys(configs?.data?.pages).includes(conf.key)) {
                  page_custom_configs.push({
                    ...conf,
                    sections: Object.keys(configs?.data?.pages[conf?.key]),
                  });
                }
                return Object.keys(configs?.data?.pages).includes(conf.key);
              });
              setRouteConfigs([
                { ...pageConfigs[0], sections: [] },
                ...page_custom_configs,
              ]);
              console.log("CUSTOM SECTIONS", [
                { ...pageConfigs[0], sections: [] },
                ...page_custom_configs,
              ]);
              localStorage.removeItem('page_configs');
              localStorage.setItem('page_configs', JSON.stringify(page_custom_configs));
              setProjectConfigs(configs?.data?.questions);
            })
            .catch(() => push("/"))
            .finally(() => setLoading(false));
        });
    };
    getConfigs();
  }, [pageConfigs]);

  React.useEffect(() => {
    axios
      .get<DraftResponse>(`drafts/${id}`, {
        headers,
      })
      .then(({ data }) => {
        if(data.draft.contactInfo.email == "")
          data.draft.contactInfo.email = userEmail
        setInitialValues({
          ...onboardingFormSchema.cast(),
          ...data.draft,
        });
        setPrepopulated(data.prepopulated);
      })
      .catch(() => push("/"));
  }, [id, setInitialValues, page]);

  const routeIndex = routeConfigs.findIndex((pConf) => pConf.route === page);
  if (routeIndex < 0) return <></>;
  const { formGroups, buttonText } = routeConfigs[routeIndex];
  const buildSchema = schemaBuilder(
    onboardingFormSchemaDynamic(projectConfigs)
  );
  const currentPageSchema = buildSchema(formGroups);

  const handleSubmit = (values: OnboardingFormSchema) =>
    axios.put(`drafts/${id}`, values, { headers }).then(() => {
      if (routeIndex < routeConfigs.length - 1) {
        push(`/form/${id}/${routeConfigs[routeIndex + 1].route}`);
      } else {
        // push(`/form/${id}/sign/overview`);
        push(`/form/${id}/sign/preview3`);
      }
    });

  const onBack = () =>
    push(`/form/${id}/${routeConfigs[routeIndex - 1].route}`);
  return (
    <React.Fragment>
      {loading ? (
        <ClipLoader
          color={"FFFFF"}
          loading={loading}
          css={override}
          size={100}
        />
      ) : (
        <>
          <StepperComponent
            stepLength={routeConfigs.length}
            currentIndex={routeIndex}
          />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={currentPageSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const { handleChange, values } = props;

              return (
                <Form style={{ width: "35%" }}>
                  <Spacer direction="column">
                    <DisabledFieldsContext.Provider
                      value={prepopulated ? prepopulatedFields : []}
                    >
                      <Switch>
                        {routeConfigs.map(({ route, Component, sections }) => {
                          return route === "contact-info" ? (
                            <Route
                              exact
                              path={`/form/:id/${route}`}
                              key={route}
                            >
                              <Contact
                                onChange={handleChange}
                                questions={projectConfigs}
                                sections={sections || []}
                              />
                            </Route>
                          ) : (
                            <Route
                              exact
                              path={`/form/:id/${route}`}
                              // component={}
                              key={route}
                            >
                              <Component
                                questions={projectConfigs}
                                sections={sections || []}
                              />
                            </Route>
                          );
                        })}
                      </Switch>
                    </DisabledFieldsContext.Provider>
                    <Spacer>
                      <div>
                        {routeIndex > 0 && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={onBack}
                            type="button"
                          >
                            Previous
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ float: "right" }}
                        >
                          {buttonText}
                        </Button>
                      </div>
                    </Spacer>
                  </Spacer>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </React.Fragment>
  );
};

export default OnboardingForm;
