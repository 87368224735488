import * as Yup from "yup";

export const fieldSchema = Yup.object({
  key: Yup.string().default(""),
  value: Yup.string().default(""),
});

export type FieldSchema = Yup.InferType<typeof fieldSchema>;

export const formSchema = Yup.object({
  form: Yup.string().default("").required("Form name is required."),
  wetSignatureRequired: Yup.boolean()
    .default(false)
    .required("Wet signature is required."),
  fields: Yup.array().of(fieldSchema).default([]),
});

export type FormSchema = Yup.InferType<typeof formSchema>;

const projectSchema = Yup.object({
  name: Yup.string().default("").required("Project name is required."),
  companyName: Yup.string().default("Aptive Resources, LLC").required("Company Name is required."),
  stationId: Yup.string().default("").required("Station Id is required."),
  forms: Yup.array()
    .of(formSchema)
    .required("Select at least one form")
    .default([]),
  active: Yup.boolean().default(true),
});

export default projectSchema;
export type ProjectSchema = Yup.InferType<typeof projectSchema>;
