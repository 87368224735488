import * as Yup from "yup";

const authenticateFormSchema = Yup.object({
  passcode: Yup.string()
    .default("")
    .length(6)
    .required("You must enter a code to authenticate yourself."),
});

export default authenticateFormSchema;
