import { Button, createStyles, Grid, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { AuthenticatedProps } from '../Common';
import axios from '../../../axios';
import { SelectOption } from '../../../inputs/select/Select';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
   main:{
       marginTop: theme.spacing(2),
    },
   element:{
    marginTop: theme.spacing(0.1),
   },
   heading:{
    fontWeight : "bold",
   },
   button:{
    marginTop: theme.spacing(5)
   }
}));

type Prepopulation = {
   [fieldName: string]: string;
 };
 type FormsDefinition = {
   [formName: string]: FormDefinition;
 };
type FormDefinition = {
   wetSignatureRequired: boolean;
   prepopulation: Prepopulation;
 };
export type Project = {
   name: string;
   companyName: string;
   stationId: string;
   forms: FormsDefinition;
   active? : boolean;
   id: string
 }; 

const ViewProject: React.FC<AuthenticatedProps> = ({ getAccessToken, logout }) => {
    const classes=useStyles();
    const history=useHistory();
    const [token, setToken] = React.useState<string>('');
    const [project, setProject] = React.useState<Project>();
    const [forms, setForms]=React.useState<SelectOption[]>([]);
    const { projectId }: any = useParams();
    const status = useLocation();

   const headers = { Authorization: `bearer ${token}` };
   
   React.useEffect(() => {
        getAccessToken(['openid']).then(setToken);
    }, [getAccessToken, setToken]);

   React.useEffect(() => {
      getProject();
    }, [projectId]);
   
   const getProject = () => {
         
         if (projectId !== undefined) {
            axios
             .get(`projects/${projectId}`, { headers })
             .then((response) => {
                setProject(response.data);
                axios.get('configs/forms').then((formData) => {
                  const selectedForms: string[] = Object.keys(response.data?.forms);
                  const filteredFormNames: SelectOption[] = formData?.data.filter((form)=> selectedForms.indexOf(form?.value.toString())>=0 );
                  setForms(filteredFormNames);
                }); 
            });
         }
   };
    return (
        <>
        <Typography  variant="h5">{`Project ${status.state.detail}`}</Typography>
        <Grid container className={classes.main}>
            <Grid container spacing={5} className={classes.element}>
               <Grid item xs={12} sm={6} md={3}>
                  <Typography className={classes.heading}>Project Name</Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={8}>
                  <Typography>{project?.name}</Typography>
               </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.element}>
               <Grid item xs={12} sm={6} md={3}>
                  <Typography className={classes.heading}>Company Name</Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={8}>
                  <Typography>{project?.companyName}</Typography>
               </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.element}>
               <Grid item xs={12} sm={6} md={3}>
                  <Typography className={classes.heading}>Station</Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={8}>
                  <Typography>{project?.stationId}</Typography>
               </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.element}>
               <Grid item xs={12} sm={6} md={3}>
                  <Typography className={classes.heading}>Forms</Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={8}>
                  {forms.length>0 ? forms.map((form: SelectOption)=>
                      <Typography className={classes.element} key={form.value}>{form?.label}</Typography>
                  ) : "-"}
               </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.element}>
               <Grid item xs={12} sm={6} md={3}>
                  <Typography className={classes.heading}>State</Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={8}>
                  <Typography>{project?.active ? "Active" : "Inactive"}</Typography>
               </Grid>
            </Grid> 
            <Button className={classes.button} color="primary" variant="outlined" onClick={()=> history.push("/admin/projects")}>
               Back to Projects
           </Button>
        </Grid>
        </>
    )
}

export default ViewProject;