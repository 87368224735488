import React from 'react';
import { useField } from 'formik';
import { TextField, InputBaseComponentProps } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { TextboxProps } from '../textbox/Textbox';
import DisabledFieldsContext from '../../components/disabledFieldsContext/DisabledFieldsContext';

export const isValidPhoneNumber = (value: string): boolean =>
  value.replace(/\D/g, '').length === 10;

export const isValidNightPhoneNumber = (value: string): boolean => {
  var phoneNoLength = value.replace(/\D/g, '').length 
  return phoneNoLength === 10 || phoneNoLength === 0;
}

export const isValidVAEmail = (value: string): boolean => {
  var validVAEmail = value.toLowerCase().endsWith("@va.gov");
  return validVAEmail || value.length === 0;
}
  
const MaskedInput: React.FC<InputBaseComponentProps> = (props) => {
  return <InputMask {...props} mask="(999) 999 - 9999" maskChar=" " />;
};

const PhoneInput: React.FC<any> = ({ name, label, required = false, handleChange = () => {}, ...props }) => {
  const [field, meta] = useField(name);
  const disabled = React.useContext(DisabledFieldsContext).includes(name);
  const hasError = !!meta.touched && !!meta.error;
  return (
    <TextField
      required={required}
      {...props}
      fullWidth
      id={name}
      label={label}
      variant="outlined"
      {...field}
      error={hasError}
      disabled={disabled}
      helperText={meta.touched && meta.error}
      InputProps={{ inputComponent: MaskedInput }}
      onChange={(e) => {
        if (name == "contactInfo.dayPhone") {
          handleChange({
            target: { value: e.target.value, name: "contactInfo.nightPhone" },
          })
        }
        handleChange({
          target: { value: e.target.value, name:name },
        })
        
      }}
    />
  );
};

export default PhoneInput;
