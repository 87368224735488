import React from 'react';
import axios from '../../../axios';
import Spacer from '../../../components/spacer/Spacer';
import { Typography, Button, withStyles } from '@material-ui/core';
import { AuthenticatedProps } from '../Common';
import { Table, TableBody, TableRow, TableCell, TableContainer} from '@material-ui/core';
import MuiTableHead from "@material-ui/core/TableHead";
import { css } from "@emotion/react";

import ClipLoader from "react-spinners/ClipLoader";

type User = { id: string; name: string };

const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;

const Rejected: React.FC<AuthenticatedProps> = ({
  getAccessToken,
  logout,
}) => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState<string>('');
  const headers = { Authorization: `bearer ${token}` };

  React.useEffect(() => {
    getAccessToken(['openid']).then(setToken);
  }, [getAccessToken, setToken]);

  const getUsers = ()=>{
    if (token !== '') {
      var params = new URLSearchParams();
      params.append("form_status", "REJECTED");
      axios
        .get('users', { headers, params: params })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }

  React.useEffect(() => {
    if (token !== '') {
      getUsers();
    }
  }, [setUsers, token]);

  const purgeUser = (userId: string): ((event: any) => void) => (
    event
  ) => {
    console.log("userId::", userId);
    axios
      .put(`users/${userId}/purge-data`,{}, { headers })
      .then(() => 
           console.log("USER PURGED")
      ).then(()=> getUsers());
  };

  const TableHead = withStyles({
    root: {
      border: "black",
      padding: "1px",
      verticalAlign: 'top',
      width: '70%',
      background: 'linear-gradient(to right bottom, #223065, #FFFFFF)',
     
    }
  })(MuiTableHead);

  return (
    <Spacer direction="column">
      <Typography variant="h5">Rejected</Typography>
      <ClipLoader color={"FFFFF"} loading={loading} css={override} size={100} />
      <TableContainer>
          <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">User Name</TableCell>
              <TableCell align="left">Project</TableCell>
              <TableCell align="left">Reason for Rejection</TableCell>
              <TableCell align="left">Purge User Data</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
            {users.map((element, j) => 
                <TableRow key={j}>
                  <TableCell key={element["name"]+j}>{element["name"]}</TableCell>
                  <TableCell key={element["project"]+j}>{element["project_name"]}</TableCell>
                  <TableCell key={element["status"]+j}>{element["reject_notes"]}</TableCell>
                  <TableCell key={`user_${element["Nr"]}_${j}`}>
                    <Button variant="contained" style={{ float: 'left' }} onClick={purgeUser(element["id"])}>
                      Purge user data
                    </Button>
                  </TableCell>
                </TableRow>
            )}
            </TableBody>
          </Table>
      </TableContainer>
    </Spacer>
  );
};

export default Rejected;
