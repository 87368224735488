import React from 'react';
import RadioButton, { RadioButtonOption } from '../radioButton/RadioButton';

export type BooleanRadioProps = {
  name: string;
  label?: string;
  required?: boolean,
};

const booleanRadioOptions: RadioButtonOption[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const BooleanRadio: React.FC<BooleanRadioProps> = (props) => (
  <RadioButton {...props} options={booleanRadioOptions} />
);

export default BooleanRadio;
