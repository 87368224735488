import React from 'react';
import Textbox from '../../inputs/textbox/Textbox';
import { buildInputName } from '../../utils/Utils';
import Spacer from '../spacer/Spacer';

export type CityStateCountryProps = {
  formGroup: string;
};

const CityStateCountry: React.FC<CityStateCountryProps> = ({ formGroup }) => {
  const inputName = buildInputName(formGroup);
  return (
    <Spacer items={3}>
      <Textbox required={true} name={inputName('city')} label="City" />
      <Textbox required={true} name={inputName('state')} label="State" />
      <Textbox required={true} name={inputName('country')} label="Country" />
    </Spacer>
  );
};

export default CityStateCountry;
