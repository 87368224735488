import * as Yup from "yup";

const startFormSchema = Yup.object({
  email: Yup.string()
    .default("")
    .email("Email address must be valid.")
    .required(
      "Enter your aptive or personal email to begin the onboarding process."
    ),
    password: Yup.string().default("").required("Password is required."),
});

export type StartFormSchema = Yup.InferType<typeof startFormSchema>;
export default startFormSchema;
