import React from "react";
import Spacer from "../../../../components/spacer/Spacer";
import { buildInputName } from "../../../../utils/Utils";
import { Typography, Divider } from "@material-ui/core";
import AdditionalExplanation, {
  AdditionalExplanationProps,
} from "../../../../components/additionalExplanation/AdditionalExplanation";
import BooleanRadio from "../../../../inputs/booleanRadio/BooleanRadio";
import { CompProps } from "../../PageConfigs";

const inputName = buildInputName("additionalInfo");

const questionsAdditional: AdditionalExplanationProps[] = [
  {
    name: inputName("relatives"),
    id: "relatives",
    question: `Do any of your relatives work for the agency or government organization to which you are submitting this form? (Include: father, mother, husband, wife, son, daughter, brother, sister, uncle, aunt, first cousin, nephew, niece, father-in-law, mother-in-law, son-in-law, daughter-in-law, brother-in-law, sister-in-law, stepfather, stepmother, stepson, stepdaughter, stepbrother, stepsister, half brother, and half sister.)`,
    explanation: `Provide the relative's name, relationship, and the department agency or branch of the Armed Forces for which your relative works.`,
  },
  {
    name: inputName("benefits"),
    id: "benefits",
    question: `Do you receive, or have you ever applied for, retirement pay, pension, or other retired pay based on military, Federal civilian, or District of Columbia Government service?`,
    explanation: `Explain your response.`,
  },
];

const AddititionalInfo: React.FC<CompProps> = ({ questions, sections }) =>
  sections.includes("additional-info") && (
    <Spacer direction="column">
      <Typography variant="h5">Additional information</Typography>
      {questionsAdditional
        .filter((q: any) => questions.includes(q.id))
        .map(AdditionalExplanation)}
      <Divider />
    </Spacer>
  );

export default AddititionalInfo;
