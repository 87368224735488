import * as Yup from "yup";

const changePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .required(
      "Old Password is required"
    ),
  newPassword: Yup.string().default("").required("New Password is required.")
    .test('valid password', 'Password should atleast contain 8 characters', function (value) {
      return value.length >= 8
    }),
  confirmPassword: Yup.string().default("").required("This field is required")
    .test('password-match', 'Passwords must match', function (value) {
      return this.parent.newPassword === value;
    }),

});

export type ChangePasswordSchema = Yup.InferType<typeof changePasswordSchema>;
export default changePasswordSchema;