import React from 'react'
import './index.scss'


function StepperComponent({ stepLength = 0, currentIndex = 0 }) {

  return (
    <div className="stepper-container">


      <div className="step-label-container">
        <div className="circle-label active">
          {`Step ${currentIndex + 1} of ${stepLength}`}
        </div>
      </div>

      <div className="stepper-bar">
        <div className="progress-dot-bar">
          {[...Array(stepLength)].map((v, i) => {
            return (<div className={i === currentIndex
              ? 'progress-dot active '
              : 'progress-dot'}></div>)
          })}
          <div className='background-line '></div>
        </div>
      </div >
    </div >
  )
}

export { StepperComponent }
export default StepperComponent

