import * as Yup from "yup";
import { isValidSsn } from "../../../../inputs/socialSecurityInput/SocialSecurityInput";
import { isValidSsnL4 } from "../../../../inputs/socialSecurityLast4Input/SocialSecurityLast4Input";
import { isValidDate } from "../../../../inputs/dateInput/DateInput";
import cityStateCountrySchema, {
  cityStateCountrySchemaDynamic,
} from "../../../../components/cityStateCountry/CityStateCountry.schema";

const aboutYouSchema = Yup.object({
  dateOfBirth: Yup.string()
    .default("")
    .required("Date of birth is required.")
    .test("valid date", "Date entered must be valid.", isValidDate),
  placeOfBirth: cityStateCountrySchema,
  citizen: Yup.string().default("").required("Citizenship status is required."),
  citizenship: Yup.string()
    .default("")
    .when(
      "citizen",
      (value: string, schema: Yup.StringSchema): Yup.StringSchema =>
        value === "false"
          ? schema.required("Place of citizenship is required.")
          : schema
    ),
  ssn: Yup.string()
    .default("")
    .required("Social security number is required.")
    .test("valid ssn", "SSN must be 9 digits.", isValidSsn),
  ssnl4: Yup.string()
    .default("")
    .required("Social security last 4 digits is required.")
    .test("valid ssn last 4 digits", "SSN last 4 digits must be 4 digits.", isValidSsnL4),
  driversLicenseState: Yup.string().default(""),
  driversLicenseNumber: Yup.string().default("")
}).defined();

export const aboutYouSchemaDynamic = (questions) =>
  Yup.object({
    dateOfBirth: Yup.string().when("check", {
      is: () => questions.includes("dateOfBirth"),
      then: Yup.string()
        .default("")
        .required("Date of birth is required.")
        .test("valid date", "Date entered must be valid.", isValidDate),
      otherwise: Yup.string().default(""),
    }),

    placeOfBirth: cityStateCountrySchemaDynamic(questions),
    citizen: Yup.string().when("check", {
      is: () => questions.includes("citizen"),
      then: Yup.string()
        .default("")
        .required("Citizenship status is required."),
      otherwise: Yup.string().default(""),
    }),

    citizenship: Yup.string()
      .default("")
      .when(
        "citizen",
        (value: string, schema: Yup.StringSchema): Yup.StringSchema =>
          value === "false"
            ? schema.required("Country of citizenship is required.")
            : schema
      ),
    ssn: Yup.string().when("check", {
      is: () => questions.includes("ssn"),
      then: Yup.string()
        .default("")
        .required("Social security number is required.")
        .test("valid ssn", "SSN must be 9 digits.", isValidSsn),
      otherwise: Yup.string().default("")
    }),
    ssnl4: Yup.string().when("check", {
      is: () => questions.includes("ssnl4"),
      then: Yup.string()
        .default("")
        .required("Social security last 4 digits is required.")
        .test("valid ssn last 4 digits", "SSN last 4 digits must be 4 digits.", isValidSsnL4),
      otherwise: Yup.string().default("")
    }),
    driversLicenseState: Yup.string().default(""),
    driversLicenseNumber: Yup.string().default("")
  }).defined();

export type AboutYouSchema = Yup.InferType<typeof aboutYouSchema>;
export default aboutYouSchema;
