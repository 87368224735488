import React from 'react';
import axios from '../../../axios';
import Spacer from '../../../components/spacer/Spacer';
import { Typography, Link, Button, withStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, CircularProgress } from '@material-ui/core';
import { downloadFile } from '../../../utils/Utils';
import { AuthenticatedProps } from '../Common';
import { Table, TableBody, TableRow, TableCell, TableContainer} from '@material-ui/core';
import MuiTableHead from "@material-ui/core/TableHead";
import { css } from "@emotion/react";

import ClipLoader from "react-spinners/ClipLoader";

type User = { id: string; name: string };

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;

const Submissions: React.FC<AuthenticatedProps> = ({
  getAccessToken,
  logout,
}) => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState<string>('');
  const [submissions, setSubmissions] = React.useState<string[]>([]);
  const [id, setId] = React.useState<string>("");
  const [rejectComment, setRejectComment] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = React.useState<any>([]);

  React.useEffect(() => {
    getAccessToken(['openid']).then(setToken);
  }, [getAccessToken, setToken]);
  const headers = { Authorization: `bearer ${token}` };

  const downloadAll = (user_id: string): ((event: any) => void) => async (
    event
  ) => {
    const downloadArr= [...downloadLoading, user_id]
    setDownloadLoading(downloadArr);
    event.preventDefault();
    console.log("UESR ID {user_id} ", user_id);
    await axios
    .get(`submissions`, { headers, params: { user_id: user_id } })
    .then((response) => {
      setSubmissions(response.data);
      axios
        .post(
          `submissions/download`,
          { keys: response.data },
          {
            headers,
            responseType: 'arraybuffer',
          }
        )
        .then(
          downloadFile({
            type: 'octet/stream',
            filename: `${users
              .find((user) => user_id === user.id)
              .name.replace(' ', '_')}_documents.zip`,
          })
        ).finally(()=> {
          setDownloadLoading(downloadLoading.filter((ele)=> ele===user_id));
        });
      }
    )
  };

  const moveToCompleted = (userId: string): ((event: any) => void) => (
    event
  ) => {
    const user_id = userId;
    const formStatus = "COMPLETED";
    console.log("userId::", userId);
    axios
      .put(`users/${user_id}`, { formStatus }, { headers })
      .then(() => 
        axios.get('users', { headers, params: { form_status: "SUBMITTED" } })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log({ error });
        }));
  };

  const sendBackToUser = (userId: string): ((event: any) => void) => (
    event
  ) => {
    const user_id = userId;
    const formStatus = "REJECTED";
    console.log("userId::", userId);
    axios
      .put(`users/${user_id}`, { formStatus, rejectNotes: rejectComment }, { headers })
      .then(() => 
        axios.get('users', { headers, params: { form_status: "SUBMITTED" } })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log({ error });
        }))
        .finally(()=>{
          setId("");
          setOpen(false);
          setRejectComment("");
        });
  };
  const purgeUser = (userId: string): ((event: any) => void) => (
    event
  ) => {
    console.log("userId::", userId);
    axios
      .put(`users/${userId}/purge-data`,{}, { headers })
      .then(() => 
           console.log("USER PURGED")
      ).then(()=> getUsers());
  };


  const getUsers = ()=>{
    if (token !== '') {
      var params = new URLSearchParams();
      params.append("form_status", "SUBMITTED");
      axios
        .get('users', { headers, params: params })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }

  React.useEffect(() => {
    getUsers();
  }, [setUsers, token]);

  const TableHead = withStyles({
    root: {
      border: "black",
      padding: "1px",
      verticalAlign: 'top',
      width: '70%',
      background: 'linear-gradient(to right bottom, #223065, #FFFFFF)',
     
    }
  })(MuiTableHead);

  return (
    <>
    <Spacer direction="column">
      <Typography variant="h5">Submitted documents</Typography>
      <ClipLoader color={"FFFFF"} loading={loading} css={override} size={100} />
      <TableContainer>
          <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">User Name</TableCell>
              <TableCell align="left">Project</TableCell>
              <TableCell align="left">Download</TableCell>
              <TableCell align="left">Mark Completed</TableCell>
              <TableCell align="left">Reject</TableCell>
              <TableCell align="left">Purge User Data</TableCell>
              <TableCell align="left">Submitted date</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
            {users.map((element, j) => 
               <TableRow key={j}>
                  <TableCell key={`name_${element["name"]}_${j}`}>{element["name"]}</TableCell>
                  <TableCell key={element["project_name"]+j}>{element["project_name"]}</TableCell>
                  <TableCell key={`download_${element["Nr"]}_${j}`}>
                    <Link onClick={downloadAll(element["id"])} href="#">
                      <Typography>Download all documents {downloadLoading.includes(element["id"]) && <CircularProgress size={15} thickness={3}/>} </Typography>
                    </Link>
                  </TableCell>
                  <TableCell key={`complete_${element["Nr"]}_${j}`}>
                    <Button variant="contained" style={{ float: 'left' }} onClick={moveToCompleted(element["id"])}>
                      Move to completed
                    </Button>
                  </TableCell>
                  <TableCell key={`user_${element["Nr"]}_${j}`}>
                    <Button variant="contained" style={{ float: 'left' }} onClick={()=> {setId(element["id"]); setOpen(true); }}>
                      Send back to user
                    </Button>
                  </TableCell>
                  <TableCell key={`purge_${element["Nr"]}_${j}`}>
                    <Button variant="contained" style={{ float: 'left' }} onClick={purgeUser(element["id"])}>
                      Purge user data
                    </Button>
                  </TableCell>
                  <TableCell >{element["timestamp"]}</TableCell>
                </TableRow>
            )}
            </TableBody>
          </Table>
        </TableContainer>
    </Spacer>
      <Dialog 
        open={open} 
        fullWidth
        maxWidth={"sm"}
        onClose={()=>{
            setOpen(false); 
            setRejectComment(""); 
            setId("");}
        }
      >
        <DialogTitle>Reject Submission</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the reason click Reject
          </DialogContentText>
          <TextField
            required
            autoFocus
            label="Reason"
            type="text"
            multiline
            minRows={3}
            value={rejectComment}
            onChange={(e)=>setRejectComment(e.target.value)}
            helperText={"This field is required"}
            fullWidth
            variant="outlined"
            inputProps={{ maxLength: 200 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
             variant="outlined" 
             color="primary"
             onClick={()=> {
               setOpen(false); 
               setRejectComment(""); 
               setId("");}
             }
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" disabled={rejectComment===""} onClick={sendBackToUser(id)}>Reject</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Submissions;