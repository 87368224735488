import React from 'react';
import { Form, Formik } from 'formik';
import axios from '../../axios';
import { useLocation, useHistory, useRouteMatch } from 'react-router';
import Textbox from '../../inputs/textbox/Textbox';
import Spacer from '../../components/spacer/Spacer';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import authenticateFormSchema from './AuthenticateFormSchema';
import { buildFormBody } from '../../utils/Utils';
import jwt from 'jwt-decode';
import { Alert } from '@material-ui/lab';

type RouteMatchProps = {
  id: string;
};
type User = { name: string; id: string; projectId?: string };

type FormStatus = string;

type AuthProps = {
  setUserLogged: React.Dispatch<any>
}

type alert = {
  alertMessage: string,
  type: "info" | "error"
}

const AuthenticateForm: React.FC<AuthProps> = ({ setUserLogged }) => {
  const [state, setState] = React.useState({ sentCode: true, errors: null });
  const history = useHistory();
  const status = useLocation();
  const [hasForm, setHasForm] = React.useState<string>("none");
  const [alert, setAlert] = React.useState<alert>({ alertMessage: "", type: "info" });
  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>('/authenticate/:id');

  const token = localStorage.getItem('access-token')
  React.useEffect(() => {
    sendCode();
  }, []);

  const sendCode = (): void => {
    // UI sends jwt token as client_id in the payload, this is a hack to authorization working in swagger
    axios
      .post<string>(`initiate/send-passcode`, { user_id: id, client_id: token })
      .then(() => {
        setState({ ...state, sentCode: true });
      });
  };
  const handleSubmit = (values, helpers): void => {
    // UI sends jwt token as client_id in the payload, this is a hack to authorization working in swagger
    const credentials = { username: id, password: values.passcode, client_id: token };
    const payload = buildFormBody(credentials);
    axios
      .post<{
        draft_id: string;
        access_token: string;
        form_status: FormStatus;
      }>(`initiate/authenticate`, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        if (res.data["errorMessage"] === undefined) {
          const draft_id = res.data["draft_id"];
          const access_token = res.data["access_token"];
          const form_status = res.data["form_status"];
          const user: any = jwt(access_token);
          localStorage.setItem('access-token', access_token);
          setUserLogged(true);
          if (user?.roles?.includes("ROLE_ADMIN")) {
            history.push({ pathname: `/admin` })
          }
          else {
            if (form_status === "GENERATED") {
              history.push({ pathname: `/form/${draft_id}/sign/overview`, state: { status: form_status } });
            }
            else {
              const headers = { Authorization: `bearer ${access_token}` };
              if (form_status === 'ASSIGNED' || 'REJECTED') {
                axios
                  .get<User>(`users/${id}`, { headers })
                  .then((res) => {
                    if (res.data.projectId) {
                      history.push(`/form/${draft_id}/start`);
                    }
                    else {
                      setHasForm("no-form");
                    }
                  });
              }
            }
          }
        }
        else {
          setAlert({ alertMessage: res.data["errorMessage"], type: "error" });
          if (res?.data["locked"] && res?.data["locked"] === 1) {
            history.push({ pathname: `/login`, state: { errorMessage: res.data["errorMessage"] } });
          }
        }
      })
      .catch((error) => {
        helpers.setErrors({ passcode: error.response.data.detail })
      }
      );
  };

  return (
    <>
      {
        hasForm === "no-form" ?
          <Typography variant="subtitle1">
            No forms found assigned to you, please contact Aptive HR at <a href="mailto: security@aptiveresources.com">security@aptiveresources.com</a>
          </Typography>
          :
          <Formik
            initialValues={authenticateFormSchema.cast()!!}
            enableReinitialize
            initialErrors={state.errors}
            validationSchema={authenticateFormSchema}
            onSubmit={handleSubmit}
          >
            <Form style={{ width: '25%' }}>
              <Spacer direction="column">
                <Typography variant="h5">SMS authentication</Typography>
                <Typography variant="subtitle1">
                A code has been sent by SMS to the phone number you provided ending with {status?.state?.phoneNumber}.
                  When it arrives, enter it in the box below to authenticate.
                  Sending the code may take up to one minute and will expire in 10 minutes.
                  Re-send the code if necessary - if you need to change that phone number, please contact Aptive HR at
                  <a href="mailto: security@aptiveresources.com"> security@aptiveresources.com</a>

                </Typography>
                {alert.alertMessage &&
                  <Alert
                    severity={alert.type}
                    style={{ backgroundColor: "#FFFFFF", color: alert.type === "error" ? "#FF0000" : "rgb(184, 231, 251)" }}>
                    {alert.alertMessage.split(".").map((text: any) => text && <> <p>{text + "."}</p></>)}
                  </Alert>
                }
                <Textbox name="passcode" autoFocus  onFocus={e => e.currentTarget.select()} label="Enter Code" />
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={6} >
                    <Button variant="contained" color="primary" type="submit">
                      Authenticate
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} >
                    <Button variant="outlined" color="primary" onClick={sendCode} >
                      Resend Code
                    </Button>
                  </Grid>
                </Grid>
              </Spacer>
            </Form>
          </Formik>
      }
    </>
  );
};

export default AuthenticateForm;
