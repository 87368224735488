import React, { useState, useRef, ChangeEvent } from "react";
import { Button, Box, Typography, Divider } from "@material-ui/core";
import { generatePDF } from "../util";

const SSNCardUpload: React.FC = () => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  // Handle file selection
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
          setImageSrc(reader.result as string);
          generatePDF(contentRef, 'ssnCardPDF');
      }
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
    <Typography variant="h5">Social Security Card</Typography><br/>
    <Typography variant="h6">Insert a photo of your Social Security Card below</Typography><br/>
    <Typography variant="caption">Please upload a clear and legible copy of your Social Security card. This document is necessary for employment verification, payroll processing, and VetPro credentialing. Ensure that the image is not blurred and that all information is fully visible. Acceptable formats include PDF, JPG, and PNG.</Typography><br/>
    <Divider />
    <br/><br/>
    <Box>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="ssn-card-file-input"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="ssn-card-file-input">
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>

      {imageSrc && (
        <div ref={contentRef}>
            <br/><br/>
          <h1 style={
            {fontFamily: "sans-serif", color: "#323285"}
            }>Social Security Card</h1><br/>
          <img
            src={imageSrc}
            alt="Selected"
            style={{ width: "100%", maxWidth: 600 }}
          />
          <p>-</p>
        </div>
      )}
    </Box>
    </>
  );
};

export { SSNCardUpload };
export default SSNCardUpload;