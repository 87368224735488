import React from "react";
import { useField } from "formik";
import Spacer from "../../../../components/spacer/Spacer";
import { Typography, Divider } from "@material-ui/core";
import { buildInputName } from "../../../../utils/Utils";
import BooleanRadio from "../../../../inputs/booleanRadio/BooleanRadio";
import { CompProps } from "../../PageConfigs";


const inputName = buildInputName("preventAssaultInfo");

const PreventAssaultInfo: React.FC<CompProps> = ({ questions, sections }) => {
  
    return (
    sections.includes("prevent-assault-info") && (
      <Spacer direction="column">
        <Typography variant="h5">Prevent, Detect, and Respond to Sexual Abuse and Assault in Confinement Facilities</Typography>
        {questions.includes("sexualAbuse") && (
          <BooleanRadio
            required={true}
            label={" Have you ever engaged in sexual abuse in a prison, jail, holding facility, community confinement facility, juvenile facility, or other institution as defined in 42 USC 1971?"}
            name={inputName("sexualAbuse")}
          />
        )}
        <Divider />
        {questions.includes("engageSexualActivity") && (
          <BooleanRadio
            required={true}
            label={" Have you ever been convicted of engaging or attempting to engage in sexual activity facilitated by force, overt or implied threats of force, or coercion?"}
            name={inputName("engageSexualActivity")}
          />
        )}
        <Divider />
        {questions.includes("victimConsent") && (
          <BooleanRadio
            required={true}
            label={" Have you ever been convicted of engaging or attempting to engage in sexual activity where the victim did not consent or was unable to consent or refuse?"}
            name={inputName("victimConsent")}
          />
        )}
        <Divider />
        {questions.includes("civilAdminAdjudication") && (
          <BooleanRadio
            required={true}
            label={" Have you ever had a civil or administrative adjudication against you for engaging in activity described in questions 1 through 3 above?"}
            name={inputName("civilAdminAdjudication")}
          />
        )}
        <Divider />
        </Spacer>
    )
    );
  };

  export default PreventAssaultInfo;

