import React from "react";
import RadioButton, {
  RadioButtonOption,
} from "../../../inputs/radioButton/RadioButton";
import Spacer from "../../../components/spacer/Spacer";
import { Typography, Divider } from "@material-ui/core";
import { CompProps } from "../PageConfigs";

const federalServiceOptions: RadioButtonOption[] = [
  { label: "I have not had a break in service.", value: "noBreak" },
  {
    label: "My break in service was less than 60 days.",
    value: "lessThan60Days",
  },
  {
    label:
      "My break in service was greater than 60 days but less than two (2) years.",
    value: "lessThan2Years",
  },
  {
    label:
      "My break in federal service is greater than two (2) years; or I have never had federal employment as defined above.",
    value: "moreThan2YearsOrNone",
  },
];

const label =
  "I hereby certify my break in service from my last federal employment is indicated by the option selected below. Federal employment is defined as any branch of the United States military (Active, Guard, or Reserve), federal government civilian employee, (any federal government agency), or a contractor working for the federal government.";
const FederalService: React.FC<CompProps> = ({ questions, sections }) =>
  sections.includes("federal-service") && (
    <Spacer direction="column">
      <Typography variant="h5">Federal service</Typography>
      <RadioButton
        label={label}
        required={true}
        name="federalService"
        options={federalServiceOptions}
      />
      <Divider />
    </Spacer>
  );

export default FederalService;
