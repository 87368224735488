import { Button, Divider, FormHelperText } from "@material-ui/core";
import { FieldArray, Form, useField } from "formik";
import React from "react";
import axios from "../../../axios";
import Spacer from "../../../components/spacer/Spacer";
import {
  FormikSelect as Select,
  SelectOption,
} from "../../../inputs/select/Select";
import { Textbox } from "../../../inputs";
import { buildInputName } from "../../../utils/Utils";
import { Checkbox } from "@material-ui/core";
import { Link } from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import ConditionWrapper from "../../../components/conditionalWrapper/ConditionWrapper";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      marginLeft: theme.spacing(4),
      maxWidth: "70%",
    },
  })
);

const KeyValueInput: React.FC<{ formGroup: string }> = ({ formGroup }) => {
  const inputName = buildInputName(formGroup);
  return (
    <Spacer items={2}>
      <Textbox name={inputName("key")} label="Key" />
      <Textbox name={inputName("value")} label="Value" />
    </Spacer>
  );
};

const FormInput: React.FC<{ formGroup: string }> = ({ formGroup }) => {
  const [forms, setForms] = React.useState<SelectOption[]>([]);

  React.useEffect(() => {
    axios.get("configs/forms").then((response) => setForms(response.data));
  }, [setForms]);
  const inputName = buildInputName(formGroup);
  return (
    <Spacer direction="column">
      <Select name={inputName("form")} label="Form name" options={forms} />
      <Divider />
      {/* <SmallTable
        formGroup={inputName('fields')}
        itemName="Field"
        schema={fieldSchema}
        Component={KeyValueInput}
      /> */}
    </Spacer>
  );
};

function fillableLabelText(formData) {
  console.log('formData:', formData)
  if (formData.fillable)
    return formData.label + " (Remain Fillable)"
  return formData.label;
}

interface ProjectInputProps {
  handleReset: (e?: React.SyntheticEvent<any, Event>) => void;
  action: "update" | "create";
  onArchive?: () => void;
  archived?: boolean;
  handleChange: (e: any) => void;
}
const ProjectInput: React.FC<ProjectInputProps> = ({
  handleReset,
  action,
  onArchive,
  archived,
  handleChange,
}) => {
  const [forms, setForms] = React.useState<SelectOption[]>([]);
  const [{ value }, { error }] = useField("forms");
  const hasError = error && !Array.isArray(error);
  const classes = useStyles();

  React.useEffect(() => {
    axios.get("configs/forms").then((response) => setForms(response.data));
  }, [setForms]);

  return (
    <Form>
      <Spacer direction="column">
        <Textbox name="name" label="Project name" fullWidth />
        <Textbox name="companyName" label="Company Name" fullWidth />
        <Textbox name="stationId" label="Station Id" fullWidth />
        <Divider />
        <Typography variant="subtitle1">Forms</Typography>
        <>
          <FieldArray
            name={"forms"}
            render={(arrayHelpers) =>
              forms.map((_, ind) => (
                <Spacer direction="row">
                  <>
                    <Checkbox
                      color="primary"
                      key={ind}
                      name={"forms"}
                      id={"forms" + ind}
                      checked={
                        value.filter((val) => val.form === _.value).length == 1
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push({
                            fields: forms[ind]["projectSpecificQuestions"].map(
                              (question) => ({ key: question, value: "" })
                            ),
                            form: _.value,
                            wetSignatureRequired: false,
                          });
                        } else {
                          const idx = value.findIndex(
                            (x) => x.form === _.value
                          );
                          arrayHelpers.remove(idx);
                        }
                      }}
                    />
                    {fillableLabelText(_)} -
                    <Link
                      target="_blank"
                      rel="noopener"
                      href={forms[ind]["referenceLink"]}
                    >
                      {forms[ind]["referenceLabel"]}
                    </Link>
                  </>
                  <ConditionWrapper
                    dependency={forms[ind]["projectSpecificQuestions"].length}
                    condition={(val) =>
                      val > 0 && value.some((form) => form.form === _.value)
                    }
                  >
                    <FieldArray
                      name={`forms[${ind}].fields`}
                      render={(arrayHelpers) => {
                        const idx = value.findIndex((x) => x.form === _.value);
                        return forms[ind]["projectSpecificQuestions"].map(
                          (question, indicator) => (
                            <Spacer direction="row">
                              <>
                                <Textbox
                                  className={classes.main}
                                  key={`formSpecificQuestion-${ind}-${indicator}`}
                                  name={`forms[${idx}].fields[${indicator}].value`}
                                  label={question}
                                  value={value[idx]?.fields[indicator].value}
                                  onChange={handleChange}
                                />
                              </>
                            </Spacer>
                          )
                        );
                      }}
                    />
                  </ConditionWrapper>
                </Spacer>
              ))
            }
          />
          {hasError && (
            <FormHelperText error style={{ margin: "0px 14px" }}>
              {error}
            </FormHelperText>
          )}
        </>
        <Grid container spacing={1}>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              save project
            </Button>
          </Grid>
          {action == "update" && (
            <Grid item>
              {archived ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onArchive}
                  startIcon={<UnarchiveIcon />}
                >
                  Unarchive Project
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onArchive}
                  startIcon={<ArchiveIcon />}
                >
                  Archive Project
                </Button>
              )}
            </Grid>
          )}
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleReset}>
              reset
            </Button>
          </Grid>
        </Grid>
      </Spacer>
    </Form>
  );
};

export default ProjectInput;
