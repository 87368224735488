import * as Yup from "yup";

const nonZenefitsUserFormSchema = Yup.object({
  name: Yup.string().default("").required("You must first and last name to create a new user."),
  phoneNumber: Yup.string().default("").required("You must enter a phone numnber to create a new user"),
  userEmail: Yup.string().default("").email("Email address must be valid.").required("You must enter an email to create a new user."),
  project: Yup.string().default("").required("Project selection is required"),
});

export type NonZenefitsUserFormSchema = Yup.InferType<typeof nonZenefitsUserFormSchema>;
export default nonZenefitsUserFormSchema;
