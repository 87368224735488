import { Typography, Divider, FormHelperText } from "@material-ui/core";
import React from "react";
import Spacer from "../../../../components/spacer/Spacer";
import RadioButton from "../../../../inputs/radioButton/RadioButton";
import { FormikSelect as Select } from "../../../../inputs/select/Select";
import SocialSecurityInput from "../../../../inputs/socialSecurityInput/SocialSecurityInput";
import { Textbox, DropDown } from "../../../../inputs";
import { buildInputName } from "../../../../utils/Utils";
import { CompProps } from "../../PageConfigs";

const inputName = buildInputName("personalInfo");

const mapToOption = (i) => ({
  label: i,
  value: i,
});

const feetOptions = ["1", "2", "3", "4", "5", "6"].map(mapToOption);
const inchesOptions = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
].map(mapToOption);
const genderOptions = ["Female", "Male", "Other"].map(mapToOption);

const raceOptions = [
  { label: "American Indian", value: "American Indian" },
  { label: "Alaskan Native", value: "Alaskan Native" },
  { label: "Asian or Pacific Islander", value: "Asian or Pacific Islander" },
  { label: "Hispanic", value: "Hispanic" },
  { label: "Black Non-Hispanic", value: "Black Non-Hispanic" },
  { label: "White Non-Hispanic", value: "White Non-Hispanic" },
];

const maritalStatusOptions = [
  {label: "Married", value: "Married"},
  {label: "Separated", value: "Separated"},
  {label: "Divorced", value: "Divorced"},
  {label: "Widowed", value: "Widowed"},
  {label: "Not Married", value: "Not Married"},
  {label: "Other", value: "Other"}
]

const PersonalInfo: React.FC<CompProps> = ({ questions, sections }) => {
  return (
    <Spacer direction="column">
      <Typography variant="h5">Personal information</Typography>
      {(questions.includes("heightFeet") ||
        questions.includes("heightInches")) && (
        <Typography variant="subtitle1">What is your height?</Typography>
      )}

      <Spacer items={2}>
        {questions.includes("heightFeet") && (
          <Select
            name={inputName("heightFeet")}
            label="Height (feet)"
            options={feetOptions}
          />
        )}
        {questions.includes("heightInches") && (
          <Select
            name={inputName("heightInches")}
            label="Height (inches)"
            options={inchesOptions}
          />
        )}
      </Spacer>

      {questions.includes("weightLBs") && (
        <>
          <Typography variant="subtitle1">What is your weight?</Typography>
          <Textbox name={inputName("weight")} label="Weight (pounds)" />
        </>
      )}
      {questions.includes("eyeColor") && (
        <>
          <Typography variant="subtitle1">What is your eye color?</Typography>
          <Textbox name={inputName("eyeColor")} label="Eye color" />
        </>
      )}
      {questions.includes("hairColor") && (
        <>
          <Typography variant="subtitle1">What is your hair color?</Typography>
          <Textbox name={inputName("hairColor")} label="Hair color" />
        </>
      )}
      {questions.includes("maritalStatus") && (
        <>
          <Typography variant="subtitle1">What is your Marital Status?</Typography>
          <Select options={maritalStatusOptions} label="Maritul Status" name={inputName("maritalStatus")} />
          <FormHelperText>
            These are the acceptable answers on this government form - Please
            choose the one most acceptable to you
          </FormHelperText>
        </>
      )}
      {questions.includes("gender") && (
        <RadioButton
          required={true}
          name={inputName("gender")}
          options={genderOptions}
          label="What is your gender?"
        />
      )}
      {questions.includes("race") && (
        <>
          <Typography variant="subtitle1">What is your race?</Typography>
          <Select options={raceOptions} label="Race" name={inputName("race")} />
          <FormHelperText>
            These are the acceptable answers on this government form - Please
            choose the one most acceptable to you
          </FormHelperText>
        </>
      )}
      
      <Divider />
    </Spacer>
  );
};

export default PersonalInfo;
