import React, { useState } from "react";
import { Table, TableBody, TableRow, TableCell, TableContainer, Typography, Button, withStyles, makeStyles, createStyles, Theme } from '@material-ui/core';
import { useHistory } from "react-router";
import { AuthenticatedProps } from '../Common';
import MuiTableHead from "@material-ui/core/TableHead";
import { css } from "@emotion/react";
import axios from '../../../axios';
import { useLocation } from "react-router-dom";
import { Project } from "../projects/ViewProject";
import Spacer from "../../../components/spacer/Spacer";
import { ClipLoader } from "react-spinners";

const UserStatus: React.FC<AuthenticatedProps> = ({ getAccessToken, logout }) => {


    const [loading, setLoading] = React.useState(true);
    const [token, setToken] = React.useState<string>();
    const status = useLocation();
    const [userData, setUserData] = useState([]);
    const history = useHistory();

    const [forms, setForms] = useState([]);
    const [projects, setProjects] = React.useState<Project[]>([]);

    const headers = { Authorization: `bearer ${token}` };

    React.useEffect(() => {
        getAccessToken(['openid']).then(setToken);
    }, [getAccessToken, setToken]);

    React.useEffect(() => {
        setUserDetails();
    }, [status]);

    const setUserDetails = async () => {
        setUserData(status?.state?.data);
        if (status?.state?.data !== null) {
            const uniqueProjects = Array.from(new Set(status?.state?.data.map((item: any) => item.projectId)));
            await Promise.all(uniqueProjects.map((project) => {
                axios
                    .get(`projects/${project}`, { headers })
                    .then((response) => setProjects(proj => ([...proj, response.data])));
            }));
            setLoading(false);
        }

    }

    const getStationId = (projectId) => {
        return projects?.find(project => project?.id == projectId)?.stationId;
    }

    const getCompanyName = (projectId) => {
        return projects?.find(project => project?.id === projectId)?.companyName
    }

    const getProjectName = (projectId) => {
        return projects?.find(project => project?.id === projectId)?.name;
    }

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            button: {
                marginTop: theme.spacing(5)
            }
        }));

    const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;

    const TableHead = withStyles({
        root: {
            border: "black",
            padding: "1px",
            verticalAlign: 'top',
            width: '70%',
            background: 'linear-gradient(to right bottom, #223065, #FFFFFF)',

        }
    })(MuiTableHead);

    const classes = useStyles();

    return <>
        <Spacer direction="column">
            <br></br>
            <Typography variant="h5">Users Created</Typography>
            <ClipLoader color={"FFFFF"} loading={loading} css={override} size={100} /><TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">User Name</TableCell>
                            <TableCell align="left">Phone number</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Project</TableCell>
                            <TableCell align="left">Station Id</TableCell>
                            <TableCell align="left">Company Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {status.state.data.map((user, j) =>
                            <TableRow key={j}>
                                <TableCell key={user["name"] + j}>{user["name"]}</TableCell>
                                <TableCell key={user["phoneNumber"] + j}>{user["phoneNumber"]}</TableCell>
                                <TableCell key={user["userEmail"] + j}>{user["userEmail"]}</TableCell>
                                <TableCell key={user["project"] + j}>{getProjectName(user['projectId'])}</TableCell>
                                <TableCell key={user["stationId"] + j}>{getStationId(user['projectId'])}</TableCell>
                                <TableCell key={user["companyName"] + j}>{getCompanyName(user['projectId'])}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <br></br>
            <br></br>
            <Button className={classes.button} color="primary" variant="outlined" onClick={() => history.push("/admin/users")}>
                Back to Users
            </Button>
        </Spacer>
    </>
}

export default UserStatus;