import React from "react";
import Spacer from "../../../components/spacer/Spacer";
import FullName from "../../../components/fullName/FullName";
import OtherNames from "../../../components/otherNames/OtherNames";
import { CompProps } from "../PageConfigs";

const YourName: React.FC<CompProps> = ({ questions, sections }) => {
  console.log("QUESTIONS THROUGH PROPS", questions);
  return (
    <Spacer direction="column">
      {sections?.includes("fullName") && (
        <FullName questions={questions} formGroup="fullName" />
      )}
      {sections?.includes("otherNames") && <OtherNames questions={questions} />}
    </Spacer>
  );
};

export default YourName;
