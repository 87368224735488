import React, { useState, useRef, ChangeEvent } from "react";
import { Button, Box } from "@material-ui/core";
import { generatePDF } from "../util";
import { Typography, Divider } from "@material-ui/core";

const DriversLicenseUpload: React.FC = () => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  // Handle file selection
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () =>
        { 
          setImageSrc(reader.result as string);
          generatePDF(contentRef, 'driversLicensePDF');
        }
      reader.readAsDataURL(file);
      
    }
    
  };

  return (
    <>
    <Typography variant="h5">Driver's License</Typography><br/>
    <Typography variant="h6">Insert a photo of your driver's license below</Typography><br/>
    <Typography variant="caption">Please upload a clear and legible copy of your driver's license. This document is necessary for identification when completing your drug screen and for VetPro credentialing, as indicated. Ensure that the image is not blurred and that all information is fully visible. Acceptable formats include PDF, JPG, and PNG.</Typography><br/>
    <Divider />
    <br/><br/>
    <Box>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="drivers-license-file-input"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="drivers-license-file-input">
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>

      {imageSrc && (
        <div ref={contentRef}>
          <br/><br/>
          <h1 style={
            {fontFamily: "sans-serif", color: "#323285"}
            }>Driver’s License</h1><br/>
          <img
            src={imageSrc}
            alt="Selected"
            style={{ width: "100%", maxWidth: 600 }}
          />
        </div>
      )}
    </Box>
    </>
  );
};

export { DriversLicenseUpload };
export default DriversLicenseUpload;