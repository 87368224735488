import React from 'react';
import { Typography } from '@material-ui/core';
import Spacer from '../../components/spacer/Spacer';

const Complete: React.FC = () => (
  <div style={{ width: '35%' }}>
    <Spacer direction="column">
      <Typography variant="h5">Complete</Typography>
      <Typography variant="subtitle1">
        Thank you for completing your onboarding forms. Please contact your
        supervisor or security@aptiveresources.com if you have questions.
      </Typography>
      <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
        Do not press the back button. Close this and any other onboarding 
        windows you may have open or submitted forms may be erased.
      </Typography>
    </Spacer>
  </div>
);

export default Complete;
